@import '../../../../defined.scss';
@import "layouts/theme.scss";


.normal-exercise-control {
  display: flex;
  flex-direction: row;
  height: 100%;
  @include themify($themes) {
    background-color: themed('light');
    /*themed('dark-grey');*/
  }

  &__groups {
    height: 80%;
    margin: .2em;
    display: flex;
  }

  &__group {
    margin-left: .2em;
    margin-right: .2em;
    padding: .2em;
    display: flex;
    border-radius: 100px;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);

    &--active {
      border-color: #C5E1A5;
    }
  }

  &__question {
    font-size: .8em;
    margin: .2em;
    border-radius: 100px;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    outline: none;
    cursor: not-allowed;

    &--clickable {
      cursor: pointer;
      color: white;

      &:hover {
        background-color: white;
        color: #333333;
      }
    }

    &--visited {
      background-color: #BDBDBD;
      color: #333333;
    }

    &--active {
      background-color: #C5E1A5;
      color: #333333;
    }

  }

  &__action {
    //display: flex;
    flex: 1
  }

  &__button {
    font-size: 14px;
    margin: .2em;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    min-width: 40px;

    @include themify($themes) {
      background-color: themed('l-button-nav-bg');
      color: $black; /*themed('l-button-nav-cl');*/
    }

    &--icon {
      min-width: 40px;
    }

    &--text {
      min-width: 90px;
    }

    &:hover {
      i {
        color: inherit !important;
      }
      @include themify($themes) {
        color: themed('l-button-nav-cl-hover') !important;
      }
    }

    &:disabled {
      cursor: not-allowed;
      @include themify($themes) {
        color: themed('l-question-button-disable-cl')
      }
      &:hover {
        @include themify($themes) {
          background-color: themed('l-button-nav-bg') !important;
          color: themed('l-question-button-disable-cl') !important
        }
      }
    }

    &:not([disabled]) {
      &:hover {
        @include themify($themes) {
          color: themed('l-button-nav-cl-hover-2');
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__groups {
      & > div {
        position: relative !important;
        overflow: auto !important;
        height: auto !important;

        & > div {
          position: relative !important;
          overflow: auto !important;
          height: auto !important;
        }
      }
    }
  }
}

#portals_exercise-question-navigation,
#portals_exercise-point {
  .ant-card-head {
    border: none;
    text-align: center;
    min-height: auto;

    @include themify($themes) {
      color: themed('dark-grey');
    }
  }

  .ant-card {
    border-radius: 0;
  }

  .ant-card-head-title {
    padding: 10px 10px 0 10px;
  }

  .ant-card-body {
    padding: 6px 10px;
  }
}

@media screen and (max-width: 700px) {
  .normal-exercise-control {
    display: block;
    &__groups {
      justify-content: center;
    }
    &__action {
      justify-content: center;
    }

    &__mobile {
      width: 100%;
      position: fixed;
      height: 50px;
      bottom: 0;
      right: 0;
      box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
      z-index: 2;
      @include themify($themes) {
        background-color: themed('light');
      }
    }

    .normal-exercise-control-question-groups {
      /*height: 110px;*/
      justify-content: center;
    }
  }
}
