.question-saving-status {
  margin-top: 10px;

  &--dark {
    .question-saving-status__message {
      //color: black;
    }
  }

  &__message {
    //color: white;
  }

  .text-small {
    font-size: 13px;
  }

  &__progress {
    position: relative;
    top: 3px;
  }
}
