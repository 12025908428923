.question-display {
  &__learning-suggestion {
    margin-top: 50px;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 10px;
  }
}
.question-feedback {
  &__title {
    font-weight: 500;
    font-size: 16px;
  }
}

@keyframes vibrate {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.question-error {
  animation: vibrate 0.6s 2;
}

.answer-log {
  p {
    margin-bottom: 0;
  }
}
