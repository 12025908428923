.pass-or-fail {
  display: flex;
  align-items: center;

  &.passed {

  }

  &.failed {
    color: rgba(red, 0.6);
  }

  .ve-close-outline {
    font-size: 12px;
  }

  &.large {
    font-size: 20px;
  }
}
