.tree-with-select-component {
  &__title {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__title-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 30px;

    &--hidden {
      visibility: hidden;
    }
  }
}
