@import 'layouts/theme.scss';
.communication {
  .notify-note {
    margin-top: 15px;
    margin-bottom: 30px;
    @include themify($themes) {
      color: themed('danger');
    }
  }
  .elementGroupBody {
    padding: 0 !important;
  }
  .send-button {
    @include themify($themes) {
      color: themed('light');
    }
    border-radius: 3px;
  }
}