.chapo {
  &__image {
    width: 40%;
    max-width: 300px;
    min-width: 200px;
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
  }
}

.left-avatar-text-right {
  &>div {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .left-avatar-text-right {
    &>div {
      display: inherit;

      .col-xs-12 {
        padding: 0 15px !important;

        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}