@import 'layouts/theme.scss';
@import 'layouts/common-style.scss';

.comment-detail {
  margin-bottom: 15px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);

  &.highlight {
    & > .ant-comment > .ant-comment-inner {
      @include themify($themes) {
        border-left: 4px solid themed('secondary-color');
      }
    }
  }

  &.warning {
    & > .ant-comment > .ant-comment-inner {
      @include themify($themes) {
        border-left: 4px solid themed('danger2');
      }
    }
  }

  .ant-comment-inner {
    padding: 10px;
  }

  .ant-avatar {
    width: 40px;
    height: 40px;
    @include center-thing();

    img {
      width: 40px;
      height: 40px;
    }

    .ant-avatar-string {
      top: 50%;
      transform: translate(-50%, -50%) !important;
    }
  }

  .ant-comment-content {
    margin-left: -62px;

    .ant-comment-content-author {
      padding-left: 60px;
      min-height: 50px;

      .ant-comment-content-author-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 2px;
        cursor: pointer;

        span {
          display: block;
          @include themify($themes) {
            color: themed('matterhorn');
          }
        }

        &__organization-name {
          font-size: 12px;
          font-weight: 400;
        }
      }

      .ant-comment-content-author-time {
        font-size: 12px;
        line-height: 15px;
        margin-left: auto;
        @include themify($themes) {
          color: themed('nobel');
        }
      }

      & > span {
        display: block;
      }
    }

    .ant-comment-content-detail {
      margin-left: 10px;
      padding: 10px 10px 10px 0;

      @include themify($themes) {
        color: themed('matterhorn');
        border-bottom: 0.5px solid themed('light-grey');
      }

      p {
        margin: 0;
      }
    }

    .ant-comment-actions {
      margin-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
      display: flex;
      font: {
        size: 12px;
      }
      line-height: 15px;

      .count-replies,
      .reply-comment {
        display: inline-block;
        padding-right: 10px;
      }

      .reply-comment {
        span {
          padding-right: 5px;
        }
      }

      li:last-child {
        flex-grow: 1;
        text-align: right;

        span {
          padding: 0;
        }
      }

      button {
        padding: 0;
      }
    }
  }

  .ant-comment-nested {
    margin: 0;

    .add-comment-wrapper {
      .ant-comment-avatar {
        display: block;
      }
    }

    @include themify($themes) {
      background-color: themed('lightcyan-color');
    }

    .ant-avatar {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .ant-comment-content-detail {
      border-bottom: none !important;
      padding-bottom: 0 !important;
    }

    .ant-comment-content-author {
      min-height: 40px;
    }

    .ant-comment-content {
      margin-left: 0;

      .ant-comment-content-author {
        padding-left: 0;
      }
    }
  }
}

.comment-detail-course {
  margin-bottom: 10px;
  @include themify($themes) {
    color: themed('nobel');
  }
}

.comment-detail-right-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .toggle-reply {
    margin-left: 15px;

    &.visible {
      visibility: visible;
    }

    &.invisible {
      visibility: hidden;
    }
  }
}

.comment-detail-assign-to-master {
  @include themify($themes) {
    color: themed('danger2');
  }
  padding: 0 15px !important;
}

.detail-a-comment {
  padding: 10px 0;
  display: flex;
  @include themify($themes) {
    color: themed('comment-main-cl');
  }
  flex-direction: row;
  //.avatar {
  //  margin-top: 4px;
  //  min-width: 40px;
  //}

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    .style-resolved {
      @include themify($themes) {
        color: themed('comment-main-cl');
      }
    }
    .bar {
      width: 100%;
      display: flex;
      margin-bottom: 2px;
      .name {
        color: #009ee5;
        font-weight: 500;
        margin-right: 5px;
      }
      .date-time {
        flex-grow: 1;
        font: {
          size: 12px;
        }
        padding-top: 3px;
        padding-left: 10px;
      }
      .resolve-button {
        border: none;
        margin: 4px 0 4px 4px;
        border-radius: 4px;
        font-weight: 400;
        outline: none;
      }
      .resolve {
        div {
          span {
            @include themify($themes) {
              color: themed('l-button-nav-cl');
            }
          }
        }
      }
      .resolved {
        background: transparent;
        @include themify($themes) {
          color: themed('comment-main-cl');
        }
        padding: 0 17px;
        font-size: 14px;
      }
      .resolved-admin {
        background: transparent;
        @include themify($themes) {
          color: themed('comment-main-cl');
        }
        padding: 0 17px;
        font-size: 14px;
      }
    }
    .content {
      @include themify($themes) {
        color: themed('comment-main-cl');
      }
      //border: solid 1px #dce4e9;
      padding: 10px 0;
      border-radius: 4px;
      width: 100%;
      white-space: pre-wrap;
    }
  }
}
.comment-detail {
  @media (max-width: 539px) {
    .ant-comment-content {
      .ant-comment-actions {
        position: relative;
        display: block;
        li {
          margin-bottom: 5px;
          &:last-child {
            text-align: right;
            width: 100%;
          }
        }
        .toggle-reply {
          position: absolute;
          top: 0;
          right: 0;
        }
        .comment-detail-right-action {
          flex-direction: column;
          align-items: flex-end;
        }
        .render-spacing {
          display: none;
        }
      }
    }
  }
}
