@import "layouts/theme.scss";

.cannot-join-virtual_class {
  cursor: not-allowed !important;
  @include themify($themes) {
    color: themed('matterhorn');
  }
}

.cannot-play-recorded {
  opacity: 0.2;
  cursor: not-allowed !important;
  @include themify($themes) {
    color: themed('matterhorn');
  }
}

.can-play-recorded {
  @include themify($themes) {
    color: themed('secondary-color');
    cursor: pointer;
  }
}

.play-icon {
  position: relative;
  top: -2px;
}

.join-icon {
  @include themify($themes) {
    color: themed('primary-color');
    cursor: pointer;
  }
}

.public-course {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__code {
    display: block;
    font-weight: bold;
  }

  &__copy {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &__copy-text {
    padding-right: 10px;
    overflow: hidden;
  }

  &__copy-icon {
    position: absolute;
    right: 0;

    &.copied {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
  }
}
