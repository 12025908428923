
.flipping-card-container {

  .container#flipping-card {
    max-width: 60rem;

    .flippy-container {
      width: 280px;
      height: 280px;
      float: left;
      padding: 1rem 1rem 1rem 1rem;

      .flippy-cardContainer-wrapper {
        .flippy-cardContainer {
          .frontStyle {
            border-color: #7837A8 !important;
            border-top: 3px solid #eee;
            opacity: 1;

            .title {
              text-align: center;
            }
          }

          .backStyle {
            border-color: #7837A8 !important;
            border-top: 3px solid #eee;
            opacity: 1;
            background-color: #f4f4f4;
          }
        }

      }
    }
  }
}



