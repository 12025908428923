.block-statement {
  //font-weight: bold;
  position: relative;
  font-style: italic;
  padding: 50px;
  text-align: center;
  top: 15px;

  p {
    text-align: center !important;
  }
}

.block-statement:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 3px;
  margin: auto;
  background: #285F9F;
}
