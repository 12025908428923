@import "layouts/theme.scss";

.detail-button {
  cursor: pointer;

  &__icon {
    margin-left: 10px;
    margin-right: 10px;

    @include themify($themes) {
      color: themed('secondary-color');
    }
  }
}