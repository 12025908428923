@import 'layouts/theme.scss';

.lecture-link {
  width: 100%;
  height: 100%;
  position: relative;

  &--open {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__description {
    @include themify($themes) {
      border: 1px solid themed('primary-color');
    }

    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px
  }
}
