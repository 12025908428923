@mixin tablet {
  @media only screen and (max-width: 991px ) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin small-mobile {
  @media only screen and (max-width: 375px) {
    @content;
  }
}