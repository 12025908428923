@import 'layouts/theme.scss';
.comment-take-popup {
  @include themify($themes) {
    padding: 15px;
    border: 1px solid themed('gray90');
    border-radius: 3px;
  }
  display: flex;
  align-items: center;
  div {
    padding-right: 20px;
  }
  .btn {
    margin-left: auto;
  }
}