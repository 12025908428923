@import 'layouts/theme.scss';
.fixed-item-children {
  padding: 5px 10px;
  border: 1px solid;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  display: inline-block;
  @include themify($themes) {
    border-color: themed('gray77');
  }
}