@import 'components/temis/stylesheet.scss';

.bdtx-report {
  .temis-report-ncbdtx {
    .ant-table {
      .ant-table-body {
        table {
          tr {
            td:first-child[colspan='1'] {
              display: table-cell;
            }
          }
        }
      }
    }
  }
}
