@import 'layouts/theme.scss';

.question-panel {
  $padding-header: 13px 20px;
  $padding-header-mobile: 13px 20px;
  $padding-content: 15px 30px;

  float: left;
  width: 100%;

  &__header {
    @include themify($themes) {
      background-color: themed('gray90');
      color: themed('matterhorn');
    }

    display: flex;
    align-items: center;
    padding: $padding-header;
    font-weight: 500;
    border: none;

    @media screen and (max-width: 768px) {
      padding: $padding-header-mobile;
    }

    &--ticked {
      @include themify($themes) {
        background-color: themed('l-question-ticked-bg');
      }
    }

    &--tickable {
      cursor: pointer;
    }

    &--inline {
      cursor: default;
    }
  }

  &__header-text {
    display: flex;
    flex-grow: 1;
    align-items: center;
    line-height: 17px;
    padding-right: 10px;
    position: relative;
    padding-left: 14px;

    .question-status-icon {
      position: absolute;
      font-size: 12px;
      left: -6px;
      top: 2px;
      margin-right: 5px;
      &.ve-close-outline {
        @include themify($themes) {
          color: themed('danger');
        }
        top: 3px;
      }
      &.ve-check {
        @include themify($themes) {
          color: themed('primary-color');
        }
      }

      @media screen and (max-width: 768px) {
        left: -14px;
      }
    }
  }

  &__score {
    padding-right: 8px;
  }

  &__header-tick {
    padding-top: 4px;
  }

  &__tick-icon {
    height: 16px;
    width: 16px;
    align-self: flex-start;

    &--ticked {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }

  &__content {
    padding: $padding-content;

    @include themify($themes) {
      color: themed('dark-grey');
    }

    img {
      max-width: 100%;
    }
  }

  &__light-blub {
    margin-left: 5px;
    @include themify($themes) {
      color: themed('secondary-color');
    }
  }

  @for $i from 10 through 48 {
    .font-size-#{$i} {
      * {
        font-size: #{$i}px;
      }
    }
  }
}
