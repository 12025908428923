.table-choose-material {
  .container-fluid,
  .row,
  .elementGroupBody,
  .ant-form-item,
  .ant-form-item-control {
    &::after,
    &::before {
      content: '';
      display: none;
    }
  }

  .elementGroupBody {
    margin: 0 !important;
  }
}
