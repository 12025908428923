@import 'layouts/theme.scss';
@import 'layouts/typography.scss';

@mixin getTagColor($bg, $color) {
  background: $bg;
  color: $color;
}

.review-program-container {
  .status {
    text-align: center;

    &.approved {
      @include getStatusColor('approved');
    }

    &.queued {
      @include getStatusColor('queued');
    }

    &.rejected {
      @include getStatusColor('rejected');
    }
  }

  .valid-status {
    .tag {
      margin-right: 0;
      padding: 4px 10px;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-left: none;
        border-radius: 0 5px 5px 0;
      }
    }

    &.approved {
      &.valid {
        .tag {
          &:first-child {
            @include themify($themes) {
              background: themed('status-approved');
              color: themed('light');
            }
          }

          &:last-child {
            @include themify($themes) {
              background: themed('light');
              color: themed('status-queued');
            }
          }
        }
      }

      &.invalid {
        .tag {
          &:first-child {
            @include themify($themes) {
              background: themed('light');
              color: themed('status-queued');
            }
          }

          &:last-child {
            @include themify($themes) {
              background: themed('status-rejected');
              color: themed('light');
            }
          }
        }
      }
    }

    &.queued {
      .tag {
        &:first-child {
          @include themify($themes) {
            background: themed('status-queued');
            color: themed('light');
          }
        }

        &:last-child {
          @include themify($themes) {
            background: themed('light');
            color: themed('status-queued');
          }
        }
      }
    }
  }
}
