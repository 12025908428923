.attachment-viewer {
  &__item {
    margin-bottom: 10px;

    &--pdf {
      height: calc(100vh - 300px);
      padding-bottom: 0;

      .lecture-pdf {
        height: 100%;
        position: relative;

        .lecture-pdf__content {
          height: calc(100% - 44px) !important;
        }

        .lecture-pdf__navigation {
          width: calc(100% + 30px);
          margin-left: -15px;
        }

        .lecture-pdf__rotation {
          right: 10px;
        }
      }
    }
  }

  &__attachments {
    // table has cell spacing 3px, add this to fix the table width
    table {
      margin-left: -3px;
      width: calc(100% + 6px) !important;
    }
  }
}