@import "layouts/theme.scss";

.reorder-question-type-box {
  display: flex;
  justify-content: space-between;

  &__fixed {
    .reorder-question-type-box__item {
      @include themify($themes) {
        border: 1px dashed themed('light-grey') !important;
      }
    }
  }

  &__moved,
  &__fixed {
    width: 49%;
    padding: 10px;
  }

  &__item {
    margin: 20px 0;
    height: auto;
    min-height: 50px;
  }

  & .avatar {
    height: auto;
    min-width: 40%;
    max-width: 150px;
    overflow: hidden;

    &>div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .video-panel::before {
      display: none;
    }

    &.full-width {
      width: 100%;
    }
  }

  & .content {
    background: white;
    height: 100%;
    display: flex;
    position: relative;
  }

  & .content-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 10px;
    width: calc(100% - 150px);
  }

  & .main {
    box-shadow: rgba(0, 0, 0, 0.12) 5px 4px 6px, rgba(0, 0, 0, 0.12) -3px -3px 4px;
    flex-grow: 1;
    width: 100%;
  }

  //Table userAnswers
  &__key-table {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 5px;
    table-layout: fixed;

    th, td  {
      border: 1px solid #e7e7e7;
      padding: 5px !important;
    }
  }

  &__key-tb-cell-content {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
  }

  &__key-tb-cell-content-main {
    flex-grow: 1;
    padding: 0 10px;
    width: calc( 100% - 150px);
  }

  &__key-tb-cell-content-avatar {
    width: 150px;

    .video-panel::before {
      display: none;
    }
  }

  &__key-tb-cell-content-main {
    display: flex;
    align-items: center;
    width: calc(100% - 150px);
  }

  @media (max-width: 992px) {
    &__fixed,
    &__moved {
      width: 50%;
    }

    &__key-tb-cell-content-avatar,
    & .avatar {
      width: 80px;
    }

    & .content-text,
    &__key-tb-cell-content-main {
      width: calc(100% - 80px);
    }
  }
}

.reorder-question-fixed-item {
  display: flex;
  position: relative;

  &__number {
    @include themify($themes) {
      color: themed('light-grey');
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
  }

  &__content {
    background: white;
    height: 100%;
    display: flex;
  }
}

.reorder-question-movable-item {
  cursor: pointer;
  display: flex;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.03);
}
