@import 'layouts/theme.scss';
.ant-descriptions {
  &.ant-descriptions-bordered {
    .ant-descriptions-view {
      border: 0;
    }
    table {
      border-collapse: separate;
      border-spacing: 3px;
      td {
        @include themify($themes) {
          border: 1px solid themed('gray77');
        }
      }
      th, td {
        padding: 8px 18px !important;
        font-weight: 500;
        font-size: 14px;
        @include themify($themes) {
          color: themed('text-secondary-color');
        }
      }
      tr {
        border: 0 !important;
        th {
          @include themify($themes) {
            background-color: themed('active-item-background-color');
          }
        }
        &:hover {
          th, td {
            @include themify($themes) {
              background-color: themed('active-item-background-color') !important;
            }
          }
        }
      }
    }
  }
}