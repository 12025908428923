@import 'layouts/theme.scss';

.assign-form {
  &__check-all {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  &__status-icon {
    border-radius: 50%;
    width: 7px;
    height: 7px;
    cursor: pointer;

    &.primary {
      @include themify($themes) {
        background-color: themed('primary-color');
      }
    }

    &.danger {
      @include themify($themes) {
        background-color: themed('danger');
      }
    }

    &--on-top-left {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &--large {
      width: 10px;
      height: 10px;
    }
  }
}
