.ant-select-tree-dropdown {
  & .ant-select-tree-child-tree {
    & .ant-select-tree-treenode-disabled {
      & .ant-select-tree-node-content-wrapper {
        & .ant-select-tree-title {
          & span {
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }
}

.tree-select-organizations {
  .ant-select-search__field {
    min-width: 20px !important;
    padding: 0;
    margin-left: 5px;
  }

  .ant-select-selection__rendered {
    margin-right: 30px;
  }

  .ant-select-selection--multiple {
    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      right: 15px;
      top: 30%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      border-left: 1px solid rgba(0, 0, 0, 0.25);
      transform-origin: 50% 50%;
      transition: 0.3s;
      transform: rotate(-55deg) skew(-15deg);
      cursor: pointer;
    }
  }
}

.tree-select-organizations.ant-select-open {
  .ant-select-selection--multiple {
    &:before {
      top: 45%;
      transform: rotate(125deg) skew(-15deg)
    }
  }
}