@import 'layouts/theme.scss';
.temis-profile-container {
  padding: 0 10px;
  margin: 0 auto;
  // maxWidth: 1300;
  width: 100%;
  .ant-card.ant-card-bordered {
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    border: none;
    height: 100%;
  }

  label,
  .ant-form-item-no-colon {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  label.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.7) !important;
  }

  .temis-profile-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  input:read-only:not(.ant-calendar-picker-input) {
    background: #f5f5f5;
    cursor: not-allowed;
    color: gray;
    &:hover {
      border-color: inherit;
    }
  }

  label.checkbox-read-only {
    &,
    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .invalid-button {
    cursor: not-allowed !important;
    * {
      pointer-events: none;
    }
    button {
      background-color: gray !important;
      @include themify($themes) {
        border-color: themed('text-muted-color');
      }
    }
  }
  .ant-radio-inner {
    border-color: rgba(0, 0, 0, 0.45);
  }
  .button-with-primary-bg {
    @include themify($themes) {
      background-color: themed('btn-primary-bg');
      color: themed('l-button-primary-cl');
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 100px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .ant-radio-wrapper {
      white-space: initial;
    }

    .ant-card-head-title {
      overflow: inherit;
      white-space: normal;
    }
  }

}
.check-data-loading {
  @include themify($themes) {
    .ant-spin-dot-item {
      background-color: themed('btn-primary-bg');
    }
    .ant-spin-text {
      color: themed('btn-primary-bg');
    }
  }
}
.page-number {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .page-number-item {
    text-align: center;
    cursor: pointer;
    margin-left: 100px;
    position: relative;
    @include themify($themes) {
      color: themed('text-muted-color');
    }
    &:after {
      position: absolute;
      content: '';
      width: 100px;
      height: 1px;
      right: 100%;
      top: 20px;
      @include themify($themes) {
        background-color: themed('text-muted-color');
      }
    }
    .item-number {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      border-radius: 50%;
      border: 2px solid;
      margin-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
      @include themify($themes) {
        border-color: themed('text-muted-color');
      }
    }

    &:first-child {
      margin-left: 0;
      &:after {
        display: none;
      }
    }
    &.active {
      @include themify($themes) {
        color: themed('primary-color');
      }
      .item-number {
        @include themify($themes) {
          border-color: themed('primary-color');
        }
      }
      &:after {
        @include themify($themes) {
          background-color: themed('primary-color');
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.page-error {
  margin-top: 20px;
  text-align: center;
  .warning-item {
    line-height: normal;
  }
}

.page-action {
  margin-top: 20px;
  display: flex;
}
.page-1 {
  .page-action {
    justify-content: center;
  }
}
.page-2 {
  .page-action {
    .submit-btn-wrapper {
      margin-left: auto;
    }
  }
}
.page-3 {
  .page-action {
    a {
      margin-left: auto;
    }
  }
}

.submit-success {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  .submit-success-icon {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    position: relative;
    margin-bottom: 30px;
    @include themify($themes) {
      background-color: themed('primary-color');
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include themify($themes) {
        color: themed('light');
        font-size: 24px;
      }
    }
  }
  .submit-success-description {
    font-size: 24px;
    line-height: normal;
    @include themify($themes) {
      color: themed('primary-color');
    }

    span {
      display: block;
      margin-bottom: 5px;
    }
  }
}

.temis-profile-other-ethnicity {
   .ant-form-item-label {
     margin-bottom: -1px;

     @media screen and (max-width: 575px) {
       margin-bottom: -8px;
     }
   }
}