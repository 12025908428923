// heading
$h-font-small: 14px;
$h-font-normal: 16px;
$h-font-medium: 18px;
$h-font-xm: 24px;
$h-font-large: 32px;
$h-font-xl: 36px;

$body-font-small: 12px;
$body-font-normal: 14px;
$body-font-medium: 16px;
$body-font-large: 18px;

$statement-font-large: 26px;

.typography {
  h1 {
    font-size: $h-font-large;
  }

  h2 {
    font-size: $h-font-xm;
  }

  h3,
  .block-item-heading {
    font-size: $h-font-medium;
    margin-bottom: 0.5em !important;

    &.font-weight-normal {
      font-weight: normal !important;
    }

    &.same-size-content {
      font-size: $body-font-normal;
    }
  }

  .content {
    font-size: $body-font-normal;
    line-height: 1.5;

    // reset default element style
    p {
      margin: 0;

      &:last-child {
        margin-bottom: 10px;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }

    * {
      text-align: justify;
    }
  }

  .small {
    h1 {
      font-size: $h-font-xm;
    }

    h2 {
      font-size: $h-font-medium;
    }

    h3,
    .block-item-heading {
      font-size: $h-font-medium;

      &.same-size-content {
        font-size: $body-font-normal;
      }
    }

    .content {
      font-size: $body-font-normal;
    }
  }

  .middle {
    h1 {
      font-size: $h-font-large;
    }

    h2 {
      font-size: $h-font-xm;
    }

    h3,
    .block-item-heading {
      font-size: $h-font-medium;

      &.same-size-content {
        font-size: $body-font-medium;
      }
    }

    .content {
      font-size: $body-font-medium;
    }
  }

  .large {
    h1 {
      font-size: $h-font-xl;
    }

    h2 {
      font-size: $h-font-large;
    }

    h3,
    .block-item-heading {
      font-size: $h-font-xm;

      &.same-size-content {
        font-size: $body-font-large;
      }
    }

    .content {
      font-size: $body-font-large;
    }

    .block-statement.content {
      font-size: $statement-font-large;
    }
  }
}

@mixin getStatusColor($type) {
  @include themify($themes) {
    color: themed('status-' + $type);
  }
}
