@import 'layouts/theme.scss';

.matching-pair-question {
  .content-text-ellipsis {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .question-status-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    &.ve-check {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }

    &.ve-close-outline {
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }

  &__key-table {
    width: 100%;
    table-layout: fixed;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 5px;

    th, td {
      border: 1px solid #e7e7e7;
      padding: 5px !important;
      width: 50%;
    }
  }

  &__key-tb-cell-content {
    display: flex;
    height: 100px;
  }

  &__key-tb-cell-content-main {
    align-self: center;
    flex-grow: 1;
    padding: 10px;
  }

  &__key-tb-cell-content-avatar {
    overflow: hidden;
    min-width: 40%;
    max-width: 150px;
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: 767px) {
    &__key-tb-cell-content-avatar {
      flex: 1 0 40%;
      max-width: 100px;
    }
  }
}

.bring-forward{
  z-index: 3;
  position: relative;
}
