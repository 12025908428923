@import 'layouts/theme.scss';
.question-status-icon {
  position: absolute;
  right: 5px;
  top: 5px;

  &.ve-check {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  &.ve-close-outline {
    @include themify($themes) {
      color: themed('danger');
    }
  }
}

.content-text-ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ddm-question-type-box {
  display: flex;
  justify-content: space-between;

  &__fixed {
    .ddm-question-type-box__item {
      @include themify($themes) {
        border: 1px dashed themed('light-grey');
      }
    }

    .ddm-question-type-box__item--solid {
      @include themify($themes) {
        border: 1px solid themed('light-grey');
      }
    }

    .ant-tag {
      white-space: normal;
      word-break: break-word;
      margin-bottom: 5px;
    }
  }

  &__item {
    margin: 10px 0;

    &--full-height {
      height: 100%;
    }

    * {
      text-align: inherit !important;
    }
  }

  & .avatar {
    height: auto;
    min-width: 40%;
    max-width: 150px;
    overflow: hidden;

    .video-panel::before {
      display: none;
    }

    &.full-width {
      width: 100%;
    }
  }

  & .content {
    background: white;
    height: 100%;
    display: flex;
    position: relative;
  }

  & .content-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: calc(100% - 150px);
  }

  & .main {
    box-shadow: rgba(0, 0, 0, 0.12) 5px 4px 6px, rgba(0, 0, 0, 0.12) -3px -3px 4px;
    flex-grow: 1;
    width: 100%;
  }

  //Table userAnswers
  &__key-table {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 5px;
    table-layout: fixed;

    th, td  {
      border: 1px solid #e7e7e7;
      padding: 5px !important;
    }
  }

  &__key-tb-cell-content {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
  }

  &__key-tb-cell-content-main {
    flex-grow: 1;
    padding: 0 10px;
    width: calc( 100% - 150px);
  }

  &__key-tb-cell-content-avatar {
    width: 150px;

    .video-panel::before {
      display: none;
    }
  }

  &__key-tb-cell-content-main {
    display: flex;
    align-items: center;
    width: calc(100% - 150px);
  }

  @media (max-width: 992px) {
    &__key-tb-cell-content-avatar,
    & .avatar {
      width: 80px;
    }

    & .content-text,
    &__key-tb-cell-content-main {
      width: calc(100% - 80px);
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0 !important;
    width: 100%;

    &__moved {
      margin-left: 5px;

      &--two-question {
        margin: 0 5px;
      }
    }

    &__box-fixed {
      margin-right: 5px;
    }
  }
}

.ddm-question-fixed-item {
  display: flex;
  position: relative;

  &.active {
    @include themify($themes) {
      border: 1.5px dashed themed('primary-color');
    }
  }

  &__number {
    @include themify($themes) {
      color: themed('light-grey');
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
  }

  &__content {
    background: white;
    height: 100%;
    display: flex;
  }

  &__title-header {
    @include themify($themes) {
      background-color: themed('lightcyan-color');
    }
  }
}

.ddm-question-movable-item {
  cursor: pointer;
  display: flex;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.03);

  @media screen and (max-width: 768px) {
    .content-text {
      &__movable-item {
        font-size: 14px;
      }
    }
  }
}

.ddm-question-type-box-selection-block {
  cursor: pointer;
  padding: 0 5px;

  &--selected {
    opacity: 0.3;
    filter: alpha(opacity=50); /* For IE8 and earlier */
  }

  &.default-match {
    background-color: #92c36a !important;
  }

  &.default-not-match {
    background-color: #f05252 !important;
  }

  &__text {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
