@import "layouts/theme.scss";

.normal-exercise-result {
  float: left;
  width: 100%;
  padding: 2em;

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;

    &--small {
      font-size: 1em;
    }
  }
  &__result {
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    margin: 1em;

    &--small {
      font-size: 1em;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__button {
    margin: .5em;
  }

  &__icon {
    @include themify($themes) {
      color: themed('primary-color');
    }

    margin-bottom: 15px;
    font-size: 50px;
  }

  &__point-section {
    margin: 15px 0 5px 0;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__point-label {
    font-size: 24px;
    margin-right: 20px;
  }

  &.quiz-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 75%;

    h3 {
      margin-top: 0;
      margin-bottom: 0;

      @include themify($themes) {
        color: themed('matterhorn');
      }
    }
  }
  .ant-alert-warning {
    border-radius: 5px;
    border: none;
    padding: 10px 20px;

    @include themify($themes) {
      background-color: themed('light-red');
    }
  }

  .ant-alert-message {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    @include themify($themes) {
      color: themed('red');
    }
  }

  .ant-alert-no-icon {
    margin-bottom: 30px;
  }
}

.test-result-wrapper {
  .download-certificate {
    color: #92c36a;
    text-decoration: underline;
    cursor: pointer;
  }
  .result-with-skills {
    height: auto !important;
  }
}
