.search-form-account {
  .sex-checkboxes,
  .status-checkboxes {
    .ant-checkbox-group {
      position: relative;
      top: -10px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .col-md-4 {
    max-width: 33.33%;
  }
}
.account-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
