@import 'layouts/theme.scss';

.page-wrapper {
  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px
  }

  &__subtitle {
    color: rgba(0, 0, 0, 0.45);

    & + .page-wrapper__indicator {
      transform: initial;
      margin: 5px 0 25px 0;
    }
  }

  &__indicator {
    width: 40px;
    height: 3px;
    @include themify($themes) {
      background: themed('a-btn-cl-active');
    }
    margin: 0;
    padding: 0;
  }

  .ant-radio-inner {
    @include themify($themes) {
      border-color: themed('btn-primary-bg');
    }
    &:after {
      @include themify($themes) {
        background: themed('btn-primary-bg');
      }
    }
  }
}

@media (max-width: 768px) {
  .page-wrapper {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-top: 20px;

    h3 {
      font-size: 20px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
