.component-image {
  &__container {
    display: flex;
    height: 100vh;
    justify-content: center;
    background-color: black;
    padding: 20px 25px;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }
}
