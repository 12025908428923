.ant-checkbox-multiline {
  padding-top: 10px !important;

  .ant-checkbox-group-item {
    display: block;
    margin: 5px 0;
  }
}

.ant-checkbox-group {
  .ant-checkbox-group-item {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.ant-multi-checkbox {
  .input-text-label {
    color: #b2b2b2;
    line-height: 36px;
    margin-bottom: -9px;
  }
}
