$mediaPath: "/media";
$main-color: #1d89e3;
$main-color-hover: #289bfa;
$main-top-bg-color: #1d89e3;
$main-top-color: #ffffff;
$white: #ffffff;
$white-link: #e4e9eb;
$white-link-hover: #d3d9dc;

$login-short-company-color: #ffffff;
$default-text-color: #7b7b7b;
$facebook-bg-color: #5d84bb;
$google-bg-color: #dd4f43;

$left-menu-with: 270px;
$left-menu-bg-color: #222d32;

$status-active: #3c763d;
$menu-left-bg-header: #1a2226;
$menu-left-color-header: #4b646f;
$menu-left-active-color: #eff0f0;
$menu-left-inactive-color: #d2d2d2;

$main-color-bg: #FFFFFF;
$main-learn-top-color-bg: #232c3b;
$main-color: #1d89e3;
$max-width-content: 1300px;
$default-line-color: #fd9126;
$left-width-space: 70px;
$top-menu-left-width-space: 100px;
$top-menu-height: 52px;
$main-color-hover: #289bfa;
$main-top-bg-color: #1d89e3;
$main-top-color: #111111;
$white: #ffffff;
$white-link: #e4e9eb;
$white-link-hover: #d3d9dc;
$image-panel-bg: #313131;
$quick-view-bg: #585858;
$main-font-family: 'Montserrat';
$ping-color: #eb7374;

//Learning

$learning-bg-color: #292929;