.etec-overview {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: #242c42;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
  }
  p {
    padding: 0;
    margin: 4px;
    line-height: 21px;
  }
  .indicator {
    margin-top: 16px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    div {
      width: 190px;
      height: 1px;
      margin: 6px;
      background: #95989a;
    }
  }
}