.custom-pagination-ellipsis {
  .ant-pagination-item-ellipsis {
    display: flex !important;
    left: -5px !important;
  }
}

.custom-category {
  .ant-row {
    .ant-select-selection--single {
      height: 42px;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
    }
  }
}
.custom-button {
  .ui-form-control {
    padding: 0 !important;
  }
}
.search-professional-groups {
  display: flex;
  align-items: flex-end;
  .search-input {
    input {
      height: 39px;
    }
  }
}
