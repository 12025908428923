.two-side-toggle {
  display: flex;
  justify-content: space-between;

  &__toggle {
    width: 20%;
  }

  &__label {
    cursor: default;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--left {
      text-align: left;
      padding-right: 10px;
    }

    &--right {
      text-align: right;
      padding-left: 10px;
      justify-content: flex-end;
    }

    &--active {
      cursor: pointer;
    }
  }
}

.menu-left-bottom {
  .two-side-toggle {
    &__label {
      color: #00bcd4 !important;
      &--active {
        color: #a1a1a1 !important;
      }
    }
  }
}