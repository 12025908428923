.summary-opinion-dialog {
  top: 0 !important;
  .ant-modal-body {
    max-height: calc(100vh - 30px) !important;
  }
  .attachment-viewer {
    .lecture-pdf__navigation {
      padding-bottom: 10px;
      width: 100%;
      margin-left: 0;
    }

    &__item {
      &--pdf {
        height: calc(100vh - 100px) !important;
        .lecture-pdf {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  @media (min-width: 768px) {
    max-width: calc(100% - 60px);
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }
}