@import 'layouts/theme.scss';

.faq-container {
  .ql-video {
    width: 600px;
    height: 400px;
  }

  .question {
    display: flex;
    flex-direction: row;

    &__content {
      @include themify($themes) {
        color: themed('question-content');
      }

      padding-left: 18px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
    }

    &__tie {
      @include themify($themes) {
        color: themed('question-tie');
      }

      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      margin-top: 5px;
      min-width: 95px;
    }
  }

  .answer-container {
    .answer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-right: 40px;

      * {
        white-space: pre-line;
      }

      a {
        @include themify($themes) {
          color: themed('answer-link') !important;
        }

        line-height: 18px;
        font: {
          size: 16px !important;
          family: Roboto, sans-serif !important;
        }
      }

      p {
        strong {
          line-height: 1.5;
        }

        span {
          & ~ a {
            margin-top: 15px;
            display: block;
          }
        }
      }

      &__content {
        @include themify($themes) {
          color: themed('question-content');
        }

        padding-left: 20px;
        font-size: 14px;
        font-weight: normal;

        h1:nth-child(2) {
          display: flex;
          align-items: center;

          & a {
            font-size: 18px !important;
            padding-left: 6px;
            padding-right: 6px;
          }
        }
      }

      &__tie {
        @include themify($themes) {
          color: themed('answer-tie');
        }

        position: relative;
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        min-width: 95px;
      }
    }
  }

  hr {
    @include themify($themes) {
      border: 2px themed('login-color-footer');
    }

    border-style: dotted none;
  }

  .button-expand {
    @include themify($themes) {
      border: 1px themed('border-expand');
    }

    position: absolute;
    right: 0;
    margin-top: 5px;
    top: 0;
    width: 38px;
    height: 38px;
    text-align: center;
    border-radius: 38px;
    cursor: pointer;
    background: url('./images/ic-down.png') no-repeat center;
  }

  .button-expanding {
    transform: rotate(180deg);
  }

  .content-expand {
    white-space: inherit;
  }

  .content-collapse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}
