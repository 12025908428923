@import 'layouts/theme.scss';

.temis-assessment-table,
.temis-manager {
  .ant-checkbox-checked .ant-checkbox-inner {
    @include themify($themes) {
      background-color: themed('primary-color');
    }
  }

  .ant-checkbox-inner {
    @include themify($themes) {
      border-color: themed('light-grey') !important;
    }
  }

  .icon-check {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  &__evidence-wrap {
    display: flex;
    align-items: center;
  }

  &__evidence {
    border-spacing: 0;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &__evidence-view-mode {
    display: block;
    padding: 5px 0;
  }

  &-document {
    .attachment-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      &-dropzone {
        border: none !important;
        padding: 0 !important;
      }
      .attachment-list {
        margin-top: 0 !important;
        width: 80%;
        span.rieinput-attachment {
          white-space: nowrap;
        }
        .attachment-item {
          padding: 5px !important;
        }
      }
    }
  }

  .ant-table-footer {
    //position: sticky;
    bottom: 0;
  }

  .ve-plus-circle {
    font-size: 18px;
    position: relative;
    top: 2px;
    @include themify($themes) {
      color: themed('secondary-color');
    }
  }

  .checkbox-assessmemt {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attachment-container-title {
    font-weight: 500;
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .attachment-container-dropzone__drag__button {
    display: inline-flex;
    align-items: center;

    svg {
      @include themify($themes) {
        color: themed('light');
      }
    }
  }
}

.table-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
}

.widget-right-wrap {
  .ant-form-item-label {
    font-family: 'Google Sans';
    label {
      span {
        font-weight: 500;
        margin-bottom: 5px;
        display: inline-block;
      }
    }
  }
}

.wish-become-teacher {
  .ant-checkbox-wrapper {
    span {
      font-weight: 500;
    }
  }

  .ant-table-row-cell-break-word {
    & > div > div:nth-child(1) {
      flex: 0 0 calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
}

.personal_orientation,
.proposing-development-remedies {
  .ant-form-item-label {
    line-height: initial;
    white-space: initial;
    text-align: initial;
    margin-bottom: 5px;
  }

  .ant-date-time-picker-wrapper {
    .ant-calendar-picker {
      min-width: initial;
    }
  }
}

.title-default {
  .elementGroup__title {
    font-weight: 500 !important;
    font-size: 16px !important;
  }
}

.footer-assessment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-evidence {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.warning-evidence {
  @include themify($themes) {
    color: themed('danger2');
  }
}

.attach-evidence {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-attachment {
  .mui-paper-container {
    div:last-child {
      min-height: initial !important;
    }
  }
}

.personal_orientation {
  .ant-form-item-control {
    line-height: 24px;
  }
  .input-text-label {
    label {
      @include themify($themes) {
        color: themed('text-secondary-color');
        font-weight: 600;
      }
    }
  }
  .ant-checkbox-wrapper {
    display: flex;
    @include themify($themes) {
      color: themed('text-secondary-color');
    }
    .ant-checkbox {
      align-self: flex-start;
      position: relative;
      top: 2px;
    }

    .ant-checkbox-disabled + span {
      @include themify($themes) {
        color: themed('dark');
        opacity: 0.8;
      }
    }
  }
  .ant-form-item-no-colon {
    @include themify($themes) {
      color: themed('text-secondary-color');
      font-weight: 600;
    }
  }
  .other-field {
    margin-top: -20px;
  }
}

.button-disabled {
  @include themify($themes) {
    background-color: themed('nobel') !important;
    color: themed('light') !important;
    border-color: themed('nobel') !important;
  }
}
