@import "layouts/theme.scss";
@import "layouts/breakpoint.scss";

.public-session {
  /*
  padding: 15px;
  */

  .ant-descriptions-item-content {
    font-weight: normal !important;;
  }

  &__replay {
    .play-icon {
      top: 0;
    }
  }

  &__goto {
    font-size: 20px;
    display: inline-block;
  }
  @include mobile {
    table {
      th,td {
        font-size: 10px !important;
      }
    }
  }
}
