@import 'layouts/themes/NEW_UI_JULY_2019-theme.scss';

.user-account-table-result {
  font-family: $NEW_UI_JULY_2019-font-roboto-regular;
  > div > div:first-child {
    table {
      background: $NEW_UI_JULY_2019-table-background-hover !important;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      min-height: 50px;
      tr {
        height: 50px !important;
        td {
          color: $NEW_UI_JULY_2019-text-muted-color;
          font-size: 14px !important;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  }
  > div > div:last-child {
    table {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}

.dot {
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
