
.date-time-picker {
  position: relative;
  svg:first-child {
    z-index: 1000;
    color: #36c2f6 !important;
    position: absolute;
    right: 0;
    top: 30px;
    width: 30px !important;
    height: 30px !important;
  }
  .date-picker, .time-picker {
    display: none !important;
  }
  &_controller {
    button:nth-child(-n+2) {
      &:hover, &:active, &:visited, &:focus {
        background-color: rgba(0, 0, 0, 0) !important;
        padding: 0;
        margin: 0;
      }
      div span {
        padding: 0 !important;
        margin: 0 !important;
      }
      .controller-label {
        padding: 0;
        margin: 0;
        display: inline-flex;
        width: 100%;
      }
    }
  }
}
