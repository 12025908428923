@import 'layouts/theme.scss';

.simple-status-send-assessment {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__sended-assessment {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  &__unsent-assessment {
    @include themify($themes) {
      color: themed('danger');
    }
  }

  &__unsent-assessment, &__sended-assessment {
    span {
      font-size: 16px;
      margin-right: 14px;
    }
  }

  &__unsent-assessment {
    margin-left: 30px;
  }
}
