@import 'layouts/theme.scss';

.header-assessment {
  padding-bottom: 20px;

  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      //font-size: 16px;
      line-height: 20px;
      //font-weight: bold;
    }
  }

  &__buttons {
    margin-left: 35px;
  }
}

.assessment-result, .header-assessment {
  padding-bottom: 20px;

  @include themify($themes) {
    //border-bottom: 1px solid themed('gray90');
  }
}

.assessment-result {
  margin: 0 -15px;

  .title, &__result {
    padding-left: 20px;
    //font-size: 16px;
    line-height: 20px;
    //font-weight: bold;
  }
}

.team-leader-assess-offline-model {
  &.custom-for-pdf {
    top: 0 !important;
    .ant-modal-body {
      max-height: calc(100vh - 30px) !important;
    }
  }
  .attachment-viewer {
    .lecture-pdf__navigation {
      padding-bottom: 10px;
      width: 100%;
      margin-left: 0;
    }

    &__item {
      &--pdf {
        height: calc(100vh - 100px) !important;
        .lecture-pdf {
          margin-bottom: 0 !important;
        }
      }
    }
  }


  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  @media (min-width: 768px) {
    max-width: calc(100% - 60px);
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }
}