.quote-content {
  margin-bottom: 25px;
  //padding: 30px;
  border-radius: 10px;
  padding: 10px 55px 30px;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 20px 20px;
  }
}
