@import "layouts/theme.scss";
@import "layouts/common-style.scss";
@import 'layouts/typography.scss';

.statistics-list {
  font-weight: 500;

  @include themify($themes) {
    color: themed('matterhorn');
  }

  &__item {
    @include themify($themes) {
      color: themed('matterhorn');
      background: themed('light');
      border: 0.5px solid themed('gray90');
    }
    @include center-thing();

    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    max-width: 300px;
    height: 120px;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  &__item-top {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 17px;

    @include center-thing();
  }

  &__item-icon {
    @include themify($themes) {
      color: themed('secondary-color');
    }
    //margin-right: 20px;
  }
}
.statistics-card-container {
  margin: 0 -5px;

  .item {
    flex: 1;
    margin: 5px;
    min-width: calc(#{percentage(1/5)} - 10px);
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    border: 2px solid transparent;

    &.success {
      @include getStatusColor('success');
    }

    &.rejected {
      @include getStatusColor('rejected');
    }

    &.approved {
      @include getStatusColor('approved');
    }

    &.queued {
      @include getStatusColor('queued');
    }

    i, .value {
      font-size: 24px;
    }

    &.active {
      border: 2px solid;
    }
  }
}
