@import 'layouts/theme.scss';

.name-assign {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;

  & span {
    margin-right: 10px;
  }

  .ve-check {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  .ve-close {
    @include themify($themes) {
      color: themed('text-secondary-color') !important;
    }

    &:hover {
      @include themify($themes) {
        color: themed('danger') !important;
      }
    }
  }
}

.action {
  float: right;
}

.action-assign {
  cursor: pointer;
}

.search-teacher {
  .mui-paper-container {
    box-shadow: none !important;
  }

  .ant-checkbox-inner {
    @include themify($themes) {
      border-color: themed('light-grey')!important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner:after {
    @include themify($themes) {
      background-color: themed('primary-color');
    }
  }
}

.search-form-assessment {
  .ant-input {
    height: 40px !important;
  }

  .ui-form-control {
    padding-top: 25px;
  }

 .ant-select-selection--multiple {
    &:after {
      display: none;
    }
  }

  .ant-form-item-control {
    line-height: 1;
    margin-top: 8px;
  }

  .ant-form-item-label {
    label {
      margin-bottom: 5px;
    }
  }

  .ant-select-selection {
    &__rendered {
      height: 40px !important;

      li {
        margin-top: 8px;
      }
    }

    &--single {
      height: 40px !important;
    }

    &-selected-value {
      margin-top: 5px;
    }
  }
}

.disabled-select-organizations {
  .ant-select-selection, .ant-select-selection__choice {
    @include themify($themes) {
      background-color: themed('gray90');
      padding-bottom: 1px;
    }
  }

  .ant-select-selection__choice__remove {
    display: none !important;
  }
}

.learn-default.custom-p-column .ant-table .ant-table-body > table th,
.learn-default.custom-p-column .ant-table .ant-table-body > table td {
  padding: 12px 14px !important;
}

.assigned-assessment {
  @include themify($themes) {
    color: themed('primary-color');
  }

  span {
    margin-right: 10px;
  }
}

.custom-m-pagination {
  .ant-pagination-options-quick-jumper input {
    margin-right: 0;
  }
}

.custom-category {
  .ant-form-select-option {
    margin-bottom: 0;
  }
}
