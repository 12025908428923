@import "layouts/theme.scss";

.simple-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 16px;
  font-weight: 500;

  @include themify($themes) {
    color: themed('matterhorn');
  }

  &__icon {
    @include themify($themes) {
      color: themed('primary-color');
      font-size: 62px;
      margin-bottom: 35px;
    }
  }

  &__icon-image {
    margin-bottom: 35px;
    height: 80px;
    width: 80px;
  }

  &.error {
    .simple-no-result__icon {
      @include themify($themes) {
        color: themed('danger');
      }
    }

    .simple-no-result__text {
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }
}
