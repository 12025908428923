@import "layouts/theme.scss";

.total-point {
  font-weight: 500;
  font-size: 16px;

  &--small {
    font-size: 14px;
  }

  &--medium {
    font-size: 36px;
  }

  &--big {
    font-size: 48px;
  }

  &.highlight {
    &.total-point--type-passed {
      .total-point__value {
        @include themify($themes) {
          color: themed('primary-color');
        }
      }
    }

    &.total-point--type-failed {
      .total-point__value {
        @include themify($themes) {
          color: themed('danger');
        }
      }
    }

    .total-point__value {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }
}
