@import '../../layouts/theme';
.about-etep-page {
  a:focus {
    border: 1px dotted;
    @include themify($themes) {
      border-color: themed('primary-color');
    }
  }
  line-height: 1.6;
  &__banner {
    width: 100%;
    margin-bottom: 30px;
  }

  &__about-link {
    @include themify($themes) {
      color: themed('secondary-color');
    }
  }

  &__list {
    margin-bottom: 2em;
  }

  &__list-item {
    margin-bottom: 7px;
  }

  @media (max-width: 767px) {
    &__banner {
      margin-bottom: 15px;
    }

    &__video {
      max-width: 100%;
      & > div {
        width: 100% !important;
        height: auto !important;
      }
    }
    p {
      iframe {
        display: block;
        width: 100%;
        height: calc((100vw - 30px) * 0.56);
      }
    }
  }

  .content {
    h3,
    h1,
    h2 {
      @include themify($themes) {
        margin-top: 20px;
        color: themed('primary-color');
        line-height: 1.8;
      }
    }
  }

  .sticky-card {
    width: 300px;
    margin-top: 35px;
  }

  .menu {
    @include themify($themes) {
      border-color: themed('gray60');
      color: themed('nobel');
    }
    font-variant: tabular-nums;
    border-right: 1px solid;
    font-size: 16px;
  }

  .item {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    @include themify($themes) {
      color: themed('nobel');
    }

    &:hover {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }

  .active {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    width: 100%;
    @include themify($themes) {
      color:  themed('primary-color');
      background-color: themed('lightcyan-color');
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 12px;
  }

  @media screen and (max-width: 991px) {
    .sticky-card {
      display: none;
    }
  }
}
