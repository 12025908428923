@import "layouts/theme.scss";

.normal-exercise {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  @include themify($themes) {
    background-color: themed('background-primary-color');
  }

  @media screen and (min-width: 769px) {
    flex: 1 1 auto;

    &__main > div {
      flex: 1 1 auto;
      height: auto !important;
    }
  }

  &__header {
    /*
    background-color: #333333;
    text-align: center;
    color: #ffffff;
    */
    font-weight: bold;
    font-size: 150%;
  }

  &__main {
    background-color: #FFFFFF;
    flex-grow: 1;
    display: flex;

    &--intro-sticky-top {
      flex-direction: column;
    }

    &--intro-sticky-left {
      flex-direction: row;
    }

    &--intro-sticky-right {
      flex-direction: row-reverse;
    }
  }

  &__intro-sticky {
    background-color: white;

    &--top {
      border-bottom: solid 1px;
    }

    &--left {
      border-right: solid 1px;
      width:  65%;
    }

    &--right {
      border-left: solid 1px;
      width:  35%;
    }
  }

  &__display {
    flex-grow: 1;
    overflow: hidden;
  }

  &__control {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__score-note {
    &:hover {
      @include themify($themes) {
        color: themed('secondary-color');
      }
    }
  }
}

.normal-exercise-duration {
  display: flex;
  align-items: center;
  justify-items: center;

  &--danger {
    @include themify($themes) {
      color: themed('danger');
    }
  }

  &__icon {
    font-size: 20px;
    margin-right: 13px;
  }

  &__text {
    &--unlimited {
      font-size: 18px
    }
  }
}

.test-result-wrapper {
  &__take-form {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
  }

  &__title {
    margin: 0 !important;
  }

  & .exercise-icon {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
  }
}
