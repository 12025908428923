@import "layouts/theme.scss";

#mark-table {
  .btn-take,
  .score-notice {
    @include themify($themes) {
      color: themed('secondary-color');
      font-size: 16px;
    }
  }
}

.marking-question-header {
  h3 {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  a {
    text-decoration: underline !important;
  }
}

.mark-icon {
  @include themify($themes) {
    color: themed('secondary-color');
    font-size: 18px;
  }
}

.table-result-peer-take {
  table {
    min-width: 100%;
  }
}