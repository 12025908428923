@import 'layouts/theme.scss';
.temis-report-ncbdtx {
  .ant-table {
    .ant-table-body {
      table {
        tr {
          td:first-child[colspan='1'] {
            display: flex;

            .ant-table-row-expand-icon {
              display: none;
            }
          }
        }
      }
    }
  }

  &.plan-develop {
    .ant-table-tbody {
      .ant-table-row-level-0 {
        .ant-table-row-expand-icon {
          visibility: hidden;
          margin-right: 0;
        }
      }

      .ant-table-row-expand-icon {
        @include themify($themes) {
          background-color: themed('gray-color');
        }
      }
    }
  }

  &.plan-develop-custom {
    .ant-table-tbody {
      .ant-table-row-expand-icon {
        @include themify($themes) {
          background-color: themed('gray-color');
        }
      }
    }
  }
}
