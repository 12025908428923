@import 'layouts/theme.scss';

.gallery-container {
  align-items: initial;
  .image-gallery {
    width: 100%;
    height: 380px;
    transition: all 1s ease;
  }
  .image-gallery-slide img {
    width: 100%;
    height: 300px;
    overflow: hidden;
    object-position: center center;
  }

  .image-gallery-thumbnail-image {
    width: auto;
    height: 52px;
  }

  @media screen and (max-width: 768px) {
    .image-gallery-content {
      margin-bottom: 10px;
    }

    .image-gallery-thumbnails-wrapper.bottom {
      margin: 0 -15px;

      @include themify($themes) {
        background-color: themed('light');
      }
    }

    .image-gallery-thumbnail-image {
      width: 100%;
    }
  }
}
