.skills-input-auto-complete-result-editor {
  height: auto;
  width: 100%;
  padding: 5px -15px;
  margin: 10px 0;
  z-index: 2;
  display: flex;
  position: relative;

  &__info {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__name-group {
    padding: 10px;
    display: flex;
  }
  &__name{
    font-weight: bold;
  }

  &__iid {
    padding: 0 .2em;
  }

  &__delete {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0
  }

  &__roles {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    transform: translatey(-12px);
  }

  .row-fluid {
    padding: 10px 0;
  }
}
