.media-lecture-with-question {
  &__overlay {
    height: calc(100% + 1px);

    @media screen and (max-width: 768px) {
      height: auto;
      width: 100%;
    }
  }
}
