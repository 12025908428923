@import "layouts/theme.scss";

.open-ended-question-form {
  .ql-toolbar {
    &.ql-snow {
      margin-top: 10px;

      @include themify($themes) {
        background-color: themed('gray60');
      }
    }
  }

  .ql-editor {
    overflow-x: hidden;
  }

  .attachment-container-dropzone__drag__title {
    @include themify($themes) {
      color: themed('matterhorn');
    }
  }

  .attachment-container-dropzone {
    border: 2px dashed #C4C4C4;
    border-radius: 5px;
  }

  &__score {
    h3 {
      margin-bottom: 10px !important;
    }
  }
}

.upload-answers {
  margin-top: 30px;
}