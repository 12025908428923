.displayed-avatar-wrapper {
  margin: 50px;
}

.edit-avatar-wrapper {
  .avatar-editor-box {
    display: inline-block;
    margin-top: 10px;
    padding-bottom: 30px;
    background-color: #EFEBE9;
    max-width: 100%;
  }

  .avatar-editor-action {
    margin-right: 20px;
  }

  .text-center {
    text-align: center;
    canvas {
      max-width: 100%;
    }
    .avatar-editor-preview {
      margin-top: 20px;
      padding: 30px;
    }
  }
}