@import "layouts/theme.scss";

.digital-plan {
  .birthday-wrapper {
    min-width: 120px !important;
  }

  .plan-tag {
    padding: 1px;
    margin: 1px;
    @include themify($themes) {
      background: themed('light-grey')
    }

    &__default {
      min-width: 80px;
    }
  }

  .ant-row,
  .ant-form-item-control {
    &::after,
    &::before {
      content: '' !important;
      display: none !important;
    }
  }

  .ant-tooltip {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    top: -45px;
    max-width: unset;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .table-without-calendar {
    .ant-table,
    .ant-table-fixed-left {
      @include themify($themes) {
        background-color: themed('light');
      }

      .ant-table-body,
      .ant-table-body-inner {
        table:not(.ant-calendar-table) {
          border-collapse: separate;
          border-spacing: 3px;
          border: none;

          th :not(.ant-calendar-column-header) {
            font-weight: 500;
          }

          td:not(.ant-calendar-cell) {
            font-weight: 300;
            position: relative;
            @include themify($themes) {
              border: 1px solid themed('light-grey');
            }

            &.stt {
              padding: 2px !important;

            }

            .stt_icon_add {
              position: absolute;
              bottom: -18px;
              left: -16px;
              z-index: 1;
            }

            .stt_icon_remove {
              position: absolute;
              bottom: 4px;
              right: 8px;
              z-index: 1;
              @include themify($themes) {
                color: themed('danger');
                border-color: themed('danger');
              }
            }

            padding: 12px 8px !important;
            @include themify($themes) {
              color: themed('text-secondary-color');
            }
          }


          tr:not(ant-calendar-tbody) {
            border: 0 !important;

            th:not(.ant-calendar-column-header) {
              @include themify($themes) {
                background-color: themed('active-item-background-color');
              }

              .ant-table-column-sorter {
                display: none;
              }
            }

            &:hover {
              th:not(.ant-calendar-column-header), td:not(.ant-calendar-cell) {
                @include themify($themes) {
                  background-color: themed('active-item-background-color') !important;
                }
              }
            }
          }
        }

        .ant-calendar-date {
          word-break: normal;
        }
      }
    }
  }
}
