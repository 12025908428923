@import 'layouts/theme.scss';

$size: 1.5em;

.block-list-container {
  ul, ol {
    list-style: none;
    padding-left: 0;

    li {
      --size: #{$size + 0.5};
      position: relative;
      margin-bottom: 0.5em;
      padding-left: var(--size);

      &::before {
        margin-top: 5px;
        font-size: inherit;
        font-weight: bold;
        position: absolute;
        left: 0;
        text-align: center;
        vertical-align: middle;
      }
    }
  }
  .unordered-list {
    ul {
      li {
        --size: #{$size};
        padding-left: var(--size);

        &::before {
          content: '';
          top: 0.2em;
          --size: #{$size - 1};
          line-height: var(--size);
          width: var(--size);
          height: var(--size);
          border-radius: 50%;
          @include themify($themes) {
            color: themed('button-primary-text-color');
            background: themed('primary-color');
          }
        }
      }
    }
  }

  .ordered-list {
    ol {
      counter-reset: my-awesome-counter;

      li {
        counter-increment: my-awesome-counter;

        &::before {
          content: counter(my-awesome-counter);
          margin-top: 0;
          top: 0.3em;

          --size: #{$size};
          line-height: var(--size);
          width: var(--size);
          height: var(--size);
          border-radius: 50%;
          @include themify($themes) {
            color: themed('button-primary-text-color');
            background: themed('primary-color');
          }
        }
      }
    }
  }

  .definition-table td {
    .term {
      @include themify($themes) {
        color: themed('primary-color');
      }
      font-weight: bold;
    }

    display: inline-block;
    top: -0.17em;
    font-size: 18px;
    vertical-align: middle;
    line-height: 1em;
    left: -1em;
  }
}
