.block-pdf {
  height: calc(100vh * 0.85);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .lecture-pdf__navigation {
    position: relative;
  }
}
