@import 'layouts/vt-theme/stylesheet/common/_variables.scss';

.normal-exercise-display {
  background-color: $background-primary-color;

  &__items {
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__result-separator {
    border-top: solid 1px;
    border-bottom: solid 1px;
    background-color: #E0E0E0;
    color: #E0E0E0;
  }

  &__result {
    margin-bottom: 1em;
  }

  &__message {
    color: red;
  }
}

.question-item-wrapper {
  position: relative;
  overflow: hidden;
  background-color: $white;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
  
  .scroll-x-table {
    .ant-table-body {
      overflow-x: auto;

      & > table {
        width: max-content;
      }
    }
  }

  .ant-notification {
    position: absolute;
    top: 0 !important;
    margin-right: 0 !important;
  }
}
