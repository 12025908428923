.front-end-update-user-avatar {

  &__avatar {
    margin-top: 4.4em;

    img {
      max-width: 100%;
    }
  }

}