@import 'layouts/stylesheet.scss';
@import 'layouts/theme.scss';

.popup-evidence {
  &__content {
    & .ant-form-item-label {
      label {
        line-height: 20px;
      }
    }
  }

  &__title, label {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    span {
      display: block;
    }
  }

  .attachment-container {
    label {
      display: block;
      margin-bottom: 10px;
    }
  }

  &__button {
    text-align: center;
    &.disable-button {
      button {
        cursor: not-allowed;
        pointer-events: none;
        @include themify($themes) {
          background-color: themed('text-muted-color');
          color: themed('light');
          border-color: themed('text-muted-color');
        }
      }
    }
  }

  textarea[name="description"] {
    margin-top: 10px;
    width: 100%;
    height: 100px;
  }

  .attachment-container-dropzone__drag__button {
    display: inline-flex;
    align-items: center;

    svg {
      @include themify($themes) {
        color: themed('light');
      }
    }
  }

  .ant-form-item-children {
    input:read-only, textarea:read-only {
      @include themify($themes) {
        background-color: themed('color-disable');
      }
      cursor: not-allowed;
    }
  }

  &__content--readonly {
    .ant-select-selection {
      @include themify($themes) {
        background-color: themed('color-disable');
      }
      cursor: not-allowed;
    }
  }
}
