@import '../../../../../layouts/typography';
@import 'layouts/stylesheet.scss';

.block-item-content {
  .content {
    a {
      @extend .link;
    }

    .ql-align-center {
      text-align: center !important;
    }

    .ql-align-right {
      text-align: right !important;
    }

    .ql-video {
      display: block;

      &.ql-align-center {
        margin: 0 auto !important;
      }

      &.ql-align-right {
        margin: 0 0 0 auto !important;
      }
    }

    * {
      text-align: unset !important;
    }
  }
}

.component-discussion {
  .comment-component {
    padding: 0;
    margin: 0;
  }
}