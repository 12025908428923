@import 'layouts/theme.scss';

.question-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &__video {
    align-self: center;
  }

  &__audio {
    align-self: center;
    width: 50%;
    max-width: 500px;
  }

  &__image {
    margin: 1em;
    max-height: 160px;
    text-align: center;
    align-self: center;
    max-width: 300px;
  }

  &:empty {
    display: none; //do not show its margin if question is empty
  }

  p {
    margin-bottom: 10px;
  }
}

.question-content-audio-replacement {
  font-style: italic;
}
