@import 'layouts/theme.scss';
.review-result {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-sent {
    @include themify($themes) {
      color: themed('secondary-color');
    }

    line-height: 21px;
  }

  &-approved {
    @include themify($themes) {
      color: themed('primary-color');
    }

    line-height: 21px;
  }

  &-rejected {
    @include themify($themes) {
      color: themed('danger');
    }

    line-height: 21px;
  }

  &__left {
    margin-right: 5px;
    align-items: center;
    display: flex;
  }

  .btn-action {
    padding: 6px 0 !important;
    width: 75px;
  }
}
