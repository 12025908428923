@import '../../../../../layouts/theme';

.history-timeline-container {
  .timeline-item {
    .ant-timeline-item {
      &-tail {
        @include themify($themes) {
          border-left-color: themed('primary-color');
        }
      }
      &-head {
        &-approved {
          @include themify($themes) {
            border-color: themed('primary-color');
            color: themed('primary-color');
          }
        }
        &-rejected {
          @include themify($themes) {
            border-color: themed('status-rejected');
            color: themed('status-rejected');
          }
        }
      }

      &-content > .row {
        > .col-md-6:last-child {
          font-weight: bold;
        }
      }
    }
  }
}
