.admin-organization-staff-remove-dialog {
  &__title {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  &__sub-title {
    font-size: 14px;
    margin-top: 0;
    padding: 0;
  }

  &__actions {
    padding-top: 30px;
    text-align: right;
  }
}
