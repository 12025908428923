.contest-information-container {
  table {
    width: 100%;
    tr {
      th {
        padding: 12px;
      }
      td {
        padding-bottom: 5px;
        &:first-child:not([class*='p-l-']) {
          padding-left: 24px !important;
          width: 30%;
        }
      }
    }
  }
  .contest-information-block-title,
  table tr th {
    font : {
      weight: bold;
      size: 16px;
    }
    padding: 0 0 15px;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.75);
  }
  .desc {
    font-weight: bold;
  }
  .icon {
    font : {
      size: 20px !important;
      weight: bold;
    }
    &-success {
      color: #52C41A !important;
    }
    &-danger {
      color: #F5222D !important;
    }
  }
  .video-for-test > div {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    > iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
