@import 'layouts/theme.scss';
.peer-result {
  display: flex;
  align-items: center;
  &__left {
    margin-right: 10px;
    .ve-check-circle {
      font-size: 16px;
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
    .ve-edit-alt {
      font-size: 14px;
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }
  &__middle {
    margin-right: 10px;
    span {
      text-transform: uppercase;
      font-weight: 500;
    }
    .result-0 {
      @include themify($themes) {
        color: themed('danger');
      }

      line-height: 21px;
    }
    .result-1 {
      @include themify($themes) {
        color: themed('secondary-color');
      }

      line-height: 21px;
    }
    .result-2 {
      @include themify($themes) {
        color: themed('blue-color');
      }

      line-height: 21px;
    }
    .result-3 {
      @include themify($themes) {
        color: themed('primary-color');
      }

      line-height: 21px;
    }
  }
  &__right {
    .ve-edit {
      font-size: 16px;
    }
  }
}

.icon-small-text {
  font-size: 12px;
}