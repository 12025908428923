@import "layouts/theme.scss";
.add-comment-wrapper {
  .ant-avatar {
    width: 40px;
    height: 40px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .ant-comment-content-detail {
    border-bottom: none !important;
  }

  .ant-comment-content-detail {
    margin: 0 !important;
  }

  textarea {
    @include themify($themes) {
      border: 0.5px solid themed('gray77');
    }
    border-radius: 0;
    resize: none;
    outline: none;
  }

  &.no-avatar {
    .ant-comment-avatar {
      margin-right: 0;
    }
  }

  .ant-comment-content-author {
    display: contents;
  }

  .add-comment {
    display: flex;
    flex-direction: row;
    //align-items: center;

    .content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 4px 0 0;
      box-sizing: border-box;

      .input-text-label {
        display: none;
      }

      .input-text {
        border: solid 1px #dce4e9;
        padding: 10px;
        border-radius: 4px;
        min-height: 48px;
        resize: none;
        outline: none;
        box-sizing: border-box;

        &:focus {
          border-color: #00bcd4;
          box-sizing: border-box;
        }
      }
      .text-input-wrapper {
        .ant-form-item-control {
          line-height: normal;
        }
        textarea {
          margin-bottom: 0 !important;
          @include themify($themes){
            color: themed('text-secondary-color');
            border: 1px solid themed('light-grey');

          }
        }
      }
    }

    .send-button {
      margin-left: 7px;
    }

    .add-comment__button-add {
      margin-top: 5px;
    }

    &__action {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .ant-form-item {
        //flex: 1 0 auto;
        margin-bottom: 0 !important;
      }

      & .add-comment__button-add {
        margin: 5px 0 10px 0 !important;
      }

      .icon-question-circle {
        margin-top: 2px;
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        .ant-form-item label {
          margin-top: 0;
        }
      }
    }

    &--simplify {
      flex-direction: column;

      .content-wrapper {
        padding: 0;
      }

      .add-comment__button-add {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      &__action {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &__button-add {
        width: auto !important;
      }
    }
  }
}
