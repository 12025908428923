.media-audio {
  background-color: #ffffff;
  border: solid 1px #f4f4f4;
  border-radius: 2px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  height: 30px;
  width: 100%;

  &__play-button {
    background-color: transparent;
    height: 100%;
    width: 35px;
    outline: none;
    border: none;
    font-size: 22px;

    &--disabled {
      img {
        filter: brightness(170%);
      }
      cursor: not-allowed;
    }
  }

  &__tracking-text {
    font-size: 12px;
    padding: 14px;
    color: #767676;
  }

  &__tracking-bar {
    flex-grow: 1;
  }
}
