@import "layouts/theme.scss";

.sessions-wrapper {
  .join-status-ok .color-by-status {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  .session-result-container {
    .ant-table-body {
      overflow: auto;
    }
  }

  .ant-table-content {
    table {
      table-layout: fixed;
    }
  }

  .sessions-table-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span, i {
      cursor: pointer;
    }
  }

  .session-name {
    text-align: center;
  }

  .student-join-icon {
    font-size: 16px;
  }

  .session-can-join {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  .session-too-early {
    @include themify($themes) {
      color: themed('secondary-color');
    }
  }

  .session-ended {
    @include themify($themes) {
      color: themed('matterhorn');
    }
  }

  .session-actions {
    display: flex;
    flex-wrap: wrap;
  }

  .session-actions-left {
    .session-actions-create {
      @include themify($themes) {
        background-color: themed('l-button-nav-cl-hover') !important;
        border: 1px solid themed('l-button-nav-cl-hover') !important;
        color: themed('light') !important;
      }
    }

    .session-actions-clone {
      @include themify($themes) {
        background-color: themed('light');
        border: 1px solid themed('l-button-nav-cl-hover');
        color: themed('l-button-nav-cl-hover');
      }
    }
  }

  .session-actions-right {
    .session-actions-delete {
      @include themify($themes) {
        background-color: themed('light');
        border: 1px solid themed('danger');
        color: themed('danger');
        margin: 0;
      }
    }
  }

  .common-antd-table {
    th {
      border-radius: 0 !important;
    }
  }

  .status-item {
    display: flex;
    align-items: center;

    .join-icon {
      margin-right: 5px;
    }
  }


  @media (min-width: 540px) {
    .session-actions-right {
      margin-top: 0;
      margin-left: auto;
    }
  }

  @media (max-width: 540px) {
    .session-actions-left {
      button {
        margin-bottom: 10px;
      }
    }
  }
}
