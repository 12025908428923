@import 'layouts/theme.scss';

.matching-pair-question-type-box-click {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__moved,
  &__question {
    width: 49%;
    padding: 10px;
  }

  &__item {
    flex-grow: 1;
    margin: 20px 0;
    min-height: 100px;
    animation: all 0.3s;

    &.default {
      .matching-pair-question-clickable-item__number {
        @include themify($themes) {
          border: 1px solid themed('gray77');
          background-color: themed('light');
          color: themed('gray77');
        }
      }
    }

    &.disabled {
      cursor: none;
      pointer-events: none;
    }

    &.hover-highlight:hover {
      @include themify($themes) {
        border-color: themed('hover-highlight-question');
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    * {
      text-align: inherit !important;
    }
  }

  & .avatar {
    height: auto;
    min-width: 40%;
    max-width: 150px;
    overflow: hidden;

    .video-panel::before {
      display: none;
    }

    &.full-width {
      width: 100%;
    }

    img {
      height: auto;
    }
  }

  & .content {
    background: white;
    height: 100%;
    display: flex;
    position: relative;
  }

  & .content-text {
    flex-grow: 1;
    padding: 10px;
    align-self: center;
  }

  & .main {
    box-shadow: rgba(0, 0, 0, 0.12) 5px 4px 6px,
      rgba(0, 0, 0, 0.12) -3px -3px 4px;
    flex-grow: 1;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .content {
      &--avatar {
        flex-direction: column;

        .avatar {
          width: 100%;
          max-width: 100%;
          justify-content: center;
          display: flex;
          padding-top: 10px;

          img {
            height: 80px;
            width: auto;
          }
        }
      }
    }
  }
}

.matching-pair-question-clickable-item {
  position: relative;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.03);
  display: flex;
  z-index: 2;

  &__number {
    position: absolute;
    left: -10px;
    top: -8px;
    width: 20px;
    height: 20px;
    @include themify($themes) {
      background-color: themed('primary-color');
      color: themed('light');
    }
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    animation: all 0.3s;
  }

  &__avatar {
    height: 100%;
    min-width: 40%;
    max-width: 150px;

    img {
      pointer-events: none;
    }
  }

  &__content-text {
    padding: 5px;
    flex-grow: 1;
  }

  &__main {
    box-shadow: rgba(0, 0, 0, 0.12) 9px 1px 6px, rgba(0, 0, 0, 0.12) 3px 1px 4px;
    padding: 0 0 0 25px;
    flex-grow: 1;
  }

  &__content {
    background: white;
    height: 100%;
    display: flex;
  }
}
