@import "layouts/theme.scss";

.report-dashboard-marking {
  .NEW_UI_JULY_2019-element-group.elementGroup.m-b-0 > .elementGroupBody {
    padding: 0 !important;
  }

  &__rubric {
    .skill-rubric-view-items {
      &__cell {
        &--name {
          width: 40%;
        }

        &--weighted {
          width: 15%;
        }

        &--marking-suggestion {
          width: 15%;
        }
      }
    }
  }

  .attachment-viewer {
    .lecture-pdf {
      &__navigation {
        padding-bottom: 10px;
        margin-left: 0 !important;
        width: 100% !important;
      }
    }
  }

  &__take-comment {
    padding: 15px;
  }
}
.content-not-allowed {
  pointer-events: none;
}

.rubric-mark-score-wrapper {
  position: relative;
}

.marking-guide {
  line-height: 1;

  img {
    max-width: 100%;
  }
}

.table-guide-marking {
  th, td {
    @include themify($themes) {
      border: 1px solid themed('gray77');
    }
    padding: 10px 15px !important;
  }
}
