.ui-img-banner {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  .text-panel {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;

    .container {
      position: relative;
      transform: translate3d(0, -50%, 0);
      -webkit-transform: translate3d(0, -50%, 0);
      top: 55%;
      z-index: 2;

      h3 {
        color: #ffffff;
        font-size: 32px;
        line-height: 40px;
        font-weight: 300;
      }
    }
  }
  .video-panel {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #000000;
      opacity: 0.7;
      z-index: 1;

    }
    .fullscreen {
      object-fit: cover;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}