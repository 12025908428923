.reorder-question-type-word-selection-block {
  cursor: pointer;
  padding: 0 5px;

  &--selected {
    opacity: 0.3;
    filter: alpha(opacity=50); /* For IE8 and earlier */
  }

  &.default-match {
    background-color: #92c36a !important;
  }

  &.default-not-match {
    background-color: #f05252 !important;
  }

  &__text {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}