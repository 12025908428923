/*
DO NOT EDIT THIS FILE
PLEASE EDIT IN themes/theme-vt.scss
*/

$primary-color: #00918c;
$secondary-color: #fe7a3d;

//color name source: https://www.color-hex.com/, https://www.htmlcsscolor.com/
$white: #ffffff;
$gray77: #c4c4c4;
$gray90: #e5e5e5;
$red: #e42b2b;
$dark-grey: #222222;
$lightcyan: #e6f4f4;
$matterhorn: #515151;
$nobel: #979797;
$blue: #3B7BBE;

$success: $primary-color;
$danger: $red;

$orange: #FE7A3D;

/* Text */
$text-primary-color: $dark-grey;
$text-secondary-color: $matterhorn;
$text-info-color: $blue;
$text-danger-color: $danger;
$text-muted-color: $nobel;

/* Border */
$border-primary-color: $gray77;

/* Background */
$background-primary-color: $white;
$background-secondary-color: $dark-grey;
$background-active-color: $lightcyan;
$background-overlay-color: rgba($dark-grey, 0.75);

/* Button */
$button-radius: 3px;
$button-primary-background-color: $primary-color;
$button-primary-text-color: $white;
$button-secondary-background-color: $gray90;
$button-secondary-text-color: $matterhorn;

/* Menu */
$main-menu-text-color: $matterhorn;
$main-menu-text-color-active: $secondary-color;

$height-header-mobile: 75px;
