@import "../../../../../layouts/vt-theme/stylesheet/common/variables";

.lecture-pdf-pagination {
  .ant-pagination-simple-pager {
    color: #000000;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin-bottom: 0 !important;

    .ant-pagination-item-link {
      background-color: transparent;
      color: #000000;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  input {
    color: $matterhorn;
    border-radius: 2px;
    width: 50px;
  }
}
