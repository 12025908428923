@import 'layouts/theme.scss';

.video-wrapper {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  &.video-wrapper-ratio {
    @media screen and (min-width: 769px) {
      padding-bottom: 56.25%; // ratio: 16:9
    }
  }

  &.video-wrapper-window {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 400px;
    height: 225px;
    z-index: 2000;
    padding-bottom: unset;
  }

  .video {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    // height: 100%;
    @media screen and (max-width: 769px) {
      position: relative;
    }
    .video-body {
      height: 100%;
      position: relative;
    }

    .lecture-video {
      height: 100%;
    }

    .close-icon-window {
      position: absolute;
      right: -15px;
      top: -15px;
      z-index: 1;
    }
  }


  @media screen and (min-width: 769px) {
    &.video-wrapper-ratio {
      padding-bottom: 56.25%; // ratio: 16:9
    }
  }

  @media screen and (max-width: 768px) {
    &.video-wrapper-window {
      display: none;
    }
  }
}

.video-heading {
  display: flex;
  align-items: center;
  h3.block-item-heading {
    margin-bottom: 0 !important;
  }
  .ve-play {
    font-size: 0.8em;
    margin-right: 5px;

    @include themify($themes) {
      color: themed('primary-color');
    }
  }
  .message-for-question-in-video {
    flex: 0 0 auto;

    @include themify($themes) {
      color: themed('primary-color');
    }
  }
}
