@import 'layouts/theme.scss';

.sco-spl-exercise-frame {
  display: block;
  width: 100%;
  height: 100vh;
  border: none;
}

.no-p-body {
  .ant-modal-body {
    padding: 0 !important;
  }
}

.inline-exercise {
  margin-bottom: 25px;
  @include themify($themes) {
    //border-bottom: 5px solid themed('primary-color');
    @media (min-width: 768px) {
      padding: 20px;
    }
    @media (max-width: 768px) {
      padding: 10px;
    }
    border: 1px solid themed('primary-color');
    border-radius: 5px;
  }
}

.inline-exercise-modal {
  .ant-modal {
    height: 100%;
    width: 100% !important;
    display: block;
    top: 0;
    margin: 0;
    padding: 0;
  }

  .ant-modal-content {
    height: 100%;
    overflow: auto;
  }
}
