@import 'layouts/theme.scss';
.wrapper-quill {
  margin-bottom: 10px;

  &.has-error {
    .ql-toolbar {
      @include themify($themes) {
        border-top-color: themed('danger');
        border-left-color: themed('danger');
        border-right-color: themed('danger');
      }
    }
    .ql-container {
      @include themify($themes) {
        border-bottom-color: themed('danger');
        border-left-color: themed('danger');
        border-right-color: themed('danger');
      }
    }
    .notify-message-contentquill {
      .ql-container {
        @include themify($themes) {
          border-bottom-color: themed('danger');
          border-left-color: themed('danger');
          border-right-color: themed('danger');
        }
      }
    }
  }
}

.ql-editor {
  min-height: 150px !important;
  max-height: 450px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  p {
    line-height: 1.78571;
  }
}
