.learn-exam-confirm {
  &__icon {
    font-size: 52px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 10px;

    &.red {
      color: red;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 300;
    color: #FFFFFF;
    margin-bottom: 14px;
    .red {
      color: red;
    }
  }

  &__preload-data-detail {
    text-align: center;
    margin-top: 10px;
  }

  &__buttons {
    text-align: center;
  }

  &__button {
    padding: 5px;
    font-size: 12px;
    border-radius: 0;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    color: white;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    margin: 15px;
    min-width: 100px;

    &:hover {
      background-color: #FFFFFF;
      color: #333333;
    }

    &--primary {
      border: 2px solid #92c36a;

      &:hover {
        background-color: #92c36a;
        color: white;
      }
    }
  }

}
