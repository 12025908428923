@import 'layouts/theme.scss';
.status-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
}

.chuadat,
.color-status-cd,
.ve-chuadat,
.ve-chuadat ~ .status-text {
  @include themify($themes) {
    color: themed('danger') !important;
  }
}

.dat,
.ve-dat,
.color-status-d,
.ve-dat ~ .status-text {
  @include themify($themes) {
    color: themed('secondary-color') !important;
  }
}

.kha,
.ve-kha,
.color-status-k,
.ve-kha ~ .status-text {
  @include themify($themes) {
    color: themed('blue-color') !important;
  }
}

.tot,
.ve-tot,
.color-status-t,
.ve-tot ~ .status-text {
  @include themify($themes) {
    color: themed('primary-color') !important;
  }
}

.temis-self-assessment-table {
  .ant-table-thead {
    font-weight: 500;
  }
}

.box-chart {
  height: 100%;
  padding-bottom: 20px;

  .mui-paper-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

   &>div {
     width: 100%;
   }
  }
}

.box-assessment {
  height: 100%;
  padding-bottom: 20px;
}

.info-self-assessment {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.widget-right-wrap {
  .chart {
    .title-default {
      .elementGroup__title {
        padding-left: 5px;
      }
    }

    .mui-paper-container {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }
}

.icon-check {
  @include themify($themes) {
    color: themed('primary-color');
  }
}

.assessment-action {
  justify-content: space-around;
}

.assessment-title {
  display: flex;
  align-items: center;

  &__export {
    margin-left: auto;
  }
}

.title-overall-comment {
  font-size: 14px;
}

.title-default {
  &__overall-comment {
    .ant-input-disabled {
      @include themify($themes) {
        color: themed('dark');
      }
    }
  }
}
