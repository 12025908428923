.rubric {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 2px;
}

.list-style-none {
  list-style: none;
  padding-left: 0;
  li {
    padding-left: 15px;
    position: relative;
    &:before {
      content: '_';
      position: absolute;
      left: 0;
      top: -7px;
    }
  }
}

.temis-report {
  .ant-list-item-meta {
    align-items: center;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
}

.sub-title-report {
  font-weight: 400;
}