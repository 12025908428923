.tree-with-filter-component {
  background-color: white;

  &__filter-form {
    padding: 0 15px;
  }

  &__tree-wrapper {
    padding: 15px;
  }
}
