@import 'layouts/stylesheet.scss';
@import 'layouts/theme.scss';

$borderColor: #e1e1e1;

.temis-container {
  background: white;
  min-height: 685px;
  padding: 30px 0;

  & > .content {
    padding: 0 0 20px 15px;
  }
  & .exam-shift-wrapper {
    display: block;
    & .select-contest-wrapper {
      display: block;
      background: unset;
      & .content {
        display: block;
        clear: both;
        & .title {
          font-size: 26px;
          font-weight: bold;
          color: #333;
          margin-bottom: 30px;
          letter-spacing: 0;
        }
      }
    }
  }

  & .nav-menu {
    display: none;
  }

  .statistics-list {
    margin-top: 0;
  }

  .temis-tab-nav {
    position: sticky;
    top: 0;
    @media (max-width: 991px) and (min-width: 768px) {
      position: initial;
    }
  }

  .temis-main-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .attachment-container-dropzone__footer {
    background-color: unset;
  }

  .ant-select-selection--multiple::before,
  .ant-select-selection--multiple::after {
    display: inline;
  }

  .temis-dashboard {
    &__chart {
      .custom-pie-chart-wrapper {
        .recharts-surface {
          overflow: visible;
        }

        .recharts-legend-wrapper {
          bottom: -15px !important;
        }
      }
    }

    &__mission {
      line-height: 1.8em;
      .ant-card {
        @include themify($themes) {
          color: themed('text-primary-color');
        }
      }

      ul {
        li {
          margin-bottom: 10px;
        }
      }

      ol {
        li {
          margin-bottom: 10px;
          font-size: 13px;
        }
        > li {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
      .list-style-none {
        list-style: none;
        padding-left: 0;
        li {
          padding-left: 15px;
          position: relative;
          &:before {
            content: '_';
            position: absolute;
            left: 0;
            top: -7px;
          }
        }
      }
      .list-style-lower-roman {
        list-style: lower-roman;
      }
      .no-padding-left {
        padding-left: 0;
      }
    }
    &__mission-title {
      font-weight: bold;
      margin-bottom: 10px;
      font-style: italic;
    }
    &__mission-sub-title {
      font-weight: 600;
      margin-bottom: 10px;
      font-style: italic;
    }
  }
}

@media screen and (max-width: 768px) {
  .temis-container {
    & .nav-menu {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      cursor: pointer;
      position: absolute;
      overflow-x: visible;
      top: 35%;
      padding-left: 24px;
      box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4);
      right: -22px;
      color: #164e99;
    }
    & .nav-menu-content {
      width: 250px;
      position: fixed;
      top: 20vh;
      left: 18px;
      height: 60vh;
      transform: translateX(-272px);
      flex-direction: row;
      display: flex;
      z-index: 10000;
      transition: all 0.5s;
      background: white;
      box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4);
    }
    & .show-nav-menu-content {
      transform: translateX(-22px);
    }
    & > .content {
      border: none;
      padding: 15px;
    }

    .temis-tab-nav {
      position: initial;
      top: initial;
    }
  }
}

.temis-header {
  .temis-header-title {
    font-size: 24px;
  }
  .temis-header-alert {
    padding: 0 12px;
  }
  @media (min-width:768px) {
    .temis-header-title {
      font-size: 28px;
    }
  }
}

.temis-dashboard {
  .dashboard-item-link {
    display: flex;
    height: 240px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  & .statistics-list {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    & .statistics-list {
      display: inherit;
    }
  }
}

.temis-evidence {
  & > div {
    margin-bottom: 30px;

    & > div {
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  .ant-table-content {
    & table {
      table-layout: fixed;

      & thead tr th {
        &:nth-child(1) {
          width: 90px;
        }
      }
    }
  }

  .ant-table-row-cell-break-word {
    & > div {
      display: flex;
      justify-content: center;

      i {
        margin: 0 10px;
      }
    }

    .detail-button {
      .icon {
        margin: 0;
        font-size: 13px;
      }
    }
  }
}

.report-result {
  &__container {
    margin-bottom: 30px;

    & > div {
      & > div {
        box-shadow: none !important;
        padding: 0 !important;
      }
    }

    .ant-table-header-column {
      text-align: center;
    }

    .ant-table-row-indent {
      ~ span.ant-table-row-spaced {
        margin-left: -20px;
      }
    }
  }

  &__school-export-excel {
    display: flex;
  }

  &__tnnn-type {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    flex: 1 1 auto;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  &__school, &__organization {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    flex: 1 1 auto;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  &__school {
    margin-bottom: 10px;
  }
}

.temis-manager {
  .tab-content {
    margin-top: 0;
  }

  .mui-page-border {
    .mui-paper-container {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px !important;
      padding: 20px !important;
    }

    .recharts-text {
      font-size: 12px;
    }
  }
}

.ant-checkbox-inner {
  @include themify($themes) {
    border-color: themed('light-grey')!important;
  }
}

.ant-radio-checked .ant-radio-inner {
  @include themify($themes) {
    border-color: themed('primary-color');
  }
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner, .ant-radio-inner::after, .ant-checkbox-inner:after {
  @include themify($themes) {
    background-color: themed('primary-color');
  }
}

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner:after {
  background-color: transparent !important;
}

.evidence-content {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  position: relative;
  max-width: 250px;
  padding-right: 10px;

  &__info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 140px;
  }

  &__icon-download {
    position: absolute;
    right: -18px;
    top: 3px;
  }

  &__preview {
    position: absolute;
    right: 15px;
  }
}

.temis-main-mission {
  ul, ul li {
    padding: 0;
    margin-bottom: 5px;

    &:before {
      content: '';
    }
  }

  ul li {
    display: flex;

    span {
      top: 3px;
      position: relative;
    }
  }
}
