.component-image {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 20px 25px;
    flex-direction: column;

    &.full-height {
      height: 100vh;
    }

    & .full-image {
      min-width: 500px;
    }
  }

  &__preview {
    max-width: 600px;
    margin: 0 auto;

    &.show-on-tooltip {
      width: 350px;
    }
  }

  .react-transform-wrapper {
    width: 100%;
    height: 100%;

    .react-transform-component {
      width: 100%;
      height: 100%;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }

  @media screen and (max-width: 768px) {
    &__container {
      padding: 10px;
    }
  }
}

.max-width-auto {
  max-width: unset !important;
}
