@import "layouts/theme.scss";
.ggg-dashboard-wrapper {
  .menu-top-wrapper {
    border-bottom: 1px solid #E1E1E1;
  }
  .ui-fly-panel {
    border-bottom: 1px solid #E1E1E1;
    .menu-top-wrapper {
      border-bottom: none;
    }
  }
  .dashboard-show-by-tab-wrapper {
    width: 100%;
    background: white;
    //padding: 20px 0 0;
    min-height: 685px;
  }
}

.user-profile-wrapper {
  .front-end-update-user-avatar__avatar {
    margin-top: 0px;
  }
  .displayed-avatar-wrapper {
    margin-top: 0px;
  }

  .update-avatar-btn {
    div,
    button {
      @include themify($themes) {
        background: themed('btn-primary-bg') !important;
        color: themed('btn-text-cl') !important;
      }
      :hover {
        opacity: 0.7;
      }
    }
  }

  h3 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .user-profile-wrapper {
    margin-top: 20px;
    h3 {
      font-size: 20px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.user-dashboard {
  .user-dashboard-link {
    display: flex;
    height: 240px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    .user-dashboard-link {
      height: 180px;
    }
  }
  @media (min-width: 992px) {
    .user-dashboard-link {
      height: 240px;
    }
  }
}
.update-avatar-title,
.change-password-title {
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 28px;
  }
}