@import 'layouts/vt-theme/stylesheet/common/_variables.scss';
@import 'layouts/common-style.scss';
@import 'layouts/theme.scss';
@import 'layouts/themes/NEW_UI_JULY_2019-theme.scss';

$nav-width: 300px;
$nav-small-width: 50px;
$nav-spacing: $nav-small-width + 10px;

.current-percent {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  @include themify($themes) {
    border: 1px solid themed('matterhorn');
  }
  &.completed {
    @include themify($themes) {
      color: themed('primary-color');
      border: 1px solid themed('primary-color');
    }
  }
}
