.comments-feedback {
  margin: 15px;
  .title {
    margin-bottom: 5px;
    font-weight: 500;
  }
  .comment-feedback {
    padding: 10px;
    margin-top: 5px;
  }
}