@import "layouts/theme.scss";

.delete-button-with-confirm {
  @include themify($themes) {
    color: themed('danger');
  }

  &--text {
    @include themify($themes) {
      border: 1px solid themed('secondary-color');
      color: themed('secondary-color');
    }

    display: inline-block;
    padding: 7px 15px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
}