.admin-common-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #eee !important;
  padding-top: 3px;

  &__name {
    font-weight: 500;
  }

  &__organization {
    margin-left: 10px;
  }

  &__delete {
    cursor: pointer;
  }
}
