@import "layouts/theme.scss";

.split-screen {
  &__icon {
    i {
      @include themify($themes) {
        font-size: 30px;
      }
    }
  }
}

.gutter-horizontal {
  cursor: ew-resize;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top:0;
    height: 100%;
    left: calc(50% - 2px);
    width: 4px;
    @include themify($themes) {
      background-color: themed('gray60');
    }
  }
}
.gutter-vertical {
  cursor: ns-resize;
}