.scholastic {
  &__select {
    .ant-form-select-option {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      .ant-form-item-label {
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }
}