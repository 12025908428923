@import 'layouts/theme.scss';

.question-hints {
  position: relative;
  padding: 5px 10px;
  margin-bottom: 20px;

  @include themify($themes){
    background-color: themed('background-active-color');
    border: 1px solid themed('primary-color');
    border-radius: 5px;
  }

  .ve-close-outline {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;

    @include themify($themes){
      color: themed('danger');
    }
  }
}
