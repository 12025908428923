@import "layouts/theme.scss";

.status-assessment {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;

  .sent-assessment-icon {
    @include themify($themes) {
      color:  themed('primary-color');
    }
  }

  .edit-assessment-icon {
    @include themify($themes) {
      color: themed('danger2');
    }
  }
}
