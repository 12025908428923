@import 'layouts/theme.scss';

.mc-text-question {
  font-size: 14px;
  label {
    line-height: 17px !important;
    margin-top: 4px;
    margin-left: -7px;

    font-family: 'Google Sans' !important;

    @include themify($themes) {
      color: themed('dark-grey') !important;
    }
  }

  svg:first-child {
    fill: $nobel !important;
  }

  .default-match {
    label {
      @include themify($themes) {
        color: themed('primary-color') !important;
      }
    }
    svg {
      @include themify($themes) {
        fill: themed('primary-color') !important;
      }
    }
  }

  .default-not-match {
    label {
      @include themify($themes) {
        color: themed('danger') !important;
      }
    }
    svg {
      @include themify($themes) {
        fill: themed('danger') !important;
      }
    }
  }

  .display-panel {
    > div {
      display: block !important;

      &:last-child {
        margin-bottom: 0 !important;
      }

      @media screen and (max-width: 768px) {
        @include themify($themes) {
          border-top: 1px solid themed('gray90');
        }

        padding: 15px 0;
        margin-bottom: 0 !important;

        &:first-child {
          margin-top: 20px !important;
        }
      }
    }
  }

  &__radio-answer {
    table-layout: fixed;
  }

  .rich-answer {
    p {
      margin: 0;
    }
  }
}
