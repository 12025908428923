@import 'layouts/theme.scss';

.assessment-pdf {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @include themify($themes) {
    background-color: themed('dark-grey');
  }

  & .react-pdf__message {
    color: $white;
  }

  &__pagination {
    position: relative;
    z-index: 9999;

    .ant-pagination-simple-pager {
      color: $white;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      margin-bottom: 0 !important;

      .ant-pagination-item-link {
        background-color: transparent;
        color: $white;

        &:hover {
          color: $secondary-color;
        }
      }
    }

    input {
      color: $matterhorn;
      border-radius: 2px;
      width: 50px;
    }
  }
}
