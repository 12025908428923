@import 'layouts/theme.scss';

.sco-layout-list {
  .progress-icon {
    .learn-nav-item__icon {
      font-size: 18px;
    }
  }
  padding: 30px 20px;
  display: flex;
  justify-content: center;

  .ant-list-item {
    a:hover {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
  }

  &__title {
    max-width: calc(100% - 60px);
  }

  &__list-nav {
    width: 80%;

    .progress-icon {
      .learn-nav-item__icon {
        margin-top: 8px;
      }
    }

    .ant-list-header {
      h3 {
        margin-bottom: 0 !important;
      }

      .current-percent {
        margin-left: 15px;
      }
    }

    @for $i from 1 through 10 {
      $paddingLeft: ($i + 1) * 22px;
      .children-level-#{$i} {
        padding-left: $paddingLeft !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
     width: 100%;

    &__list-nav {
      width: 100%;
    }
  }
}