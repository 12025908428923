@import 'layouts/theme.scss';

.inline-question {
  input {
    &.default-match {
      background-color: #92c36a !important;
    }

    &.default-not-match {
      background-color: #f05252 !important;
    }

    &:disabled {
      @include themify($themes) {
        -webkit-text-fill-color: themed('dark');
      }
      opacity: 1;
    }
  }
  div {
    &.default-match {
      .ant-select-selection {
        background-color: #92c36a !important;
      }
    }

    &.default-not-match {
      .ant-select-selection {
        background-color: #f05252 !important;
      }
    }

    &:disabled {
      @include themify($themes) {
        -webkit-text-fill-color: themed('dark');
      }
      opacity: 1;
    }
  }
  .vocab-input {
    input {
      margin-top: 20px;
    }
  }

  .inline-text {
    font-weight: bold;
    text-align: center;
  }
}
