@import 'layouts/vt-theme/stylesheet/common/_variables.scss';

$percent-margin-left: 15px;
$percent-margin-left-mobile: 20px;

.progress-bar {
  .ant-progress {
    &-inner {
      border: 0.5px solid $matterhorn;
    }
    &-bg {
      background-color: $primary-color;
    }
    &-outer {
      margin-right: calc(-2em - #{$percent-margin-left});
      padding-right: calc(2em + #{$percent-margin-left});
      @media (max-width: 768px) {
        margin-right: calc(-2em - #{$percent-margin-left-mobile});
        padding-right: calc(2em + #{$percent-margin-left-mobile});
      }
    }
    &-text {
      margin-left: $percent-margin-left;
    }
  }
}
