@import 'layouts/theme.scss';

.question {
  position: relative;

  &__icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &.ve-check {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }

    &.ve-close-outline {
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }
}
