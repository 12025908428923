@import 'layouts/theme.scss';

.tab {
  &-item {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: white;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    @include themify($themes) {
      border: 1px solid themed('primary-color');
      background-color: transparent;
      color: themed('primary-color');
    }

    &.active {
      @include themify($themes) {
        border: 1px solid themed('primary-color');
        background-color: themed('primary-color');
        color: white;
      }
    }
  }

  &-content {
    margin-top: 30px;
  }
}
