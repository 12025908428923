@import 'layouts/theme.scss';
@import 'layouts/typography.scss';

.question-status-icon {
  position: absolute;
  right: -5px;
  top: -5px;

  &.ve-check {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }

  &.ve-close-outline {
    @include themify($themes) {
      color: themed('danger');
    }
  }
}

.content-text-ellipsis {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mddm-question-type-box {
  display: flex;
  flex-direction: column;

  &__moveable_box {
    display: flex;
    flex-wrap: wrap;
    min-height: 45px;
    @media screen and (max-width: 768px) {
      .content-text {
        &__movable-item {
          font-size: 14px;
        }
      }
    }
  }

  &__fixed_box {
    display: flex;
    align-items: center;

  }

  &__item__fixed {
    width: 120px;
    max-width: 200px;
    padding: 10px;
    @include themify($themes) {
      border: 1px dashed themed('light-grey');
      background: themed('light');;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__inline {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: center;
  }

  &__table {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: center;
    //max-width: 320px;
  }

  &__box_answer {
    padding: 5px;
    height: auto;
    @include themify($themes) {
      border: 1px dashed themed('light-grey');
    }
  }

  &__box_question {
    padding: 10px 15px;
    @include themify($themes) {
      border: 1px dashed themed('light-grey');
    }
  }
}

.mddm-question-movable-item {
  cursor: pointer;
  border-radius: 3px;
  padding: 3px;
  margin: 3px;
  display: inline;
  font-size: $body-font-small;

  @include themify($themes) {
    border: 1px solid themed('light-grey');
    background: themed('light')
  }

  @media screen and (max-width: 768px) {
    .content-text {
      &__movable-item {
        font-size: 14px;
      }
    }
  }

  &__span {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
  }
}


.mddm-question-fixed-item {
  //display: flex;
  position: relative;
  display: inline-block;
  margin: 5px 0px -5px;
}

.mddm-tag {
  padding: 2px;
  margin: 0px 1px;
  @include themify($themes) {
    background: themed('light-grey')
  }
}
