.common-overlay-component {
  &__wrapper {
    position: relative;
    height: 100%;

    @media screen and (max-width: 768px){
      display: flex;
      width: 100%;
    }

    @media screen and (max-width: 896px) and (max-height: 480px) {
      display: flex;
      width: 100%;
    }
  }

  &__overlay {
    z-index: 99;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @media screen and (max-width: 768px){
      display: flex;
    }

    @media screen and (max-width: 896px) and (max-height: 480px) {
      display: flex;
    }
  }
}