@import '../../../configs/css-configuration.scss';
@import 'layouts/theme.scss';
.modal_dialog {
  max-height: 90% !important;
  height: auto;
  > div {
    width: 100%;
  }
  .dialog-content {
    height: 100% !important;
    min-height: initial !important;
    margin-top: 60px !important;
    > div:first-child {
      background: #f0f2f5 !important;
    }
    & > div > div {
      margin-top: 1px !important;
    }
  }
}

.hashbang-dialog {
  .dialog-content {
    margin-top: 0px !important;
  }
}

.no-header-dialog {
  .close-popup {
    position: absolute;
    right: -12px;
    top: -15px;
    font-size: 20px;
    border: 1px solid #505050;
    border-radius: 50%;
    width: 31px;
    text-align: center;
    background: #505050;
    opacity: 1.8;
    color: $white;
    cursor: pointer;
    padding: 2px 0 0 3px;
    i {
      cursor: pointer;
    }
  }

  .close-modal {
    background-color: white !important;
    width: 30px !important;
    height: 30px !important;
    border: none !important;
    border-radius: 22px;
    padding: 0 !important;
    z-index: 100;
    .close-icon {
      width: 24px !important;
      height: 24px !important;
      padding-top: 2px !important;
    }
  }
}

.modal_dialog_ant {
  &--default-width {
    width: 75% !important;
    max-width: 768px !important;
  }

  .ant-modal-title {
    font-size: 22px;
    padding-right: 30px;
  }

  .ant-modal-body {
    max-height: 714px;
    overflow: auto;
  }
}

.modal-full-height {
  //position: absolute !important;
  top: 0 !important;
  padding-bottom: 0 !important;

  .ant-modal-body {
    max-height: 100% !important;
    overflow: initial;
    min-height: 100vh;
  }
}

.modal_dialog_ant {
  .ant-modal-content,
  .ant-modal-header,
  .ant-modal-body {
    @include themify($themes) {
      background-color: themed('light');
    }
  }
  .ant-modal-content {
    .ant-modal-header {
      padding: 16px 20px;
    }
    .ant-modal-title {
      font-size: 16px;
      @include themify($themes) {
        color: themed('text-primary-color');
      }
    }
    .ant-modal-body {
      > .ve-close {
        z-index: 1;
        position: absolute;
        top: 17px;
        font-size: 17px;
        right: 20px;
        cursor: pointer;
        @include themify($themes) {
          color: themed('text-muted-color');
        }
      }
      padding: 20px 20px 20px 20px;
    }
  }
}

.hashbang-dialog-ant {
  top: 0;
  margin-right: 0;
  padding-bottom: 0;

  .ant-modal-content {
    min-height: 80vh;
    .ant-modal-body {
      max-height: 100vh;
    }
  }
}

.score-modal {
  .ant-descriptions {
    &.ant-descriptions-bordered {
      table {
        tr {
          th:first-child {
            width: 40%;
          }
        }
      }
    }
  }

  table {
    .ve-close-outline {
      @include themify($themes) {
        color: themed('danger');
      }
    }

    .ve-check {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }

    .ve-play {
      @include themify($themes) {
        color: themed('secondary-color');
      }
      &.disabled {
        cursor: not-allowed;
        @include themify($themes) {
          color: themed('gray77');
        }
      }
    }
  }

  .ant-table {
    .ant-table-body {
      table {
        .indent-level-1 {
          padding-left: 10px !important;
        }

        .ant-table-row-expand-icon {
          display: none;
        }

        tr {
          td:first-child {
            border-color: transparent !important;
          }

          &[class*='ant-table-row-level-0'] {
            td:first-child {
              @include themify($themes) {
                background-color: themed('active-item-background-color');
              }
            }
          }

          &[class*='ant-table-row-level-1'] {
            td:first-child {
              @include themify($themes) {
                background-color: themed('gray-color');
              }
            }
          }
        }
      }
    }
  }

  .section-wrap {
    padding-bottom: 18px;
    margin-bottom: 13px;
    @include themify($themes) {
      border-bottom: 1px solid themed('gray77');
    }
  }

  .section-title {
    font-size: 16px;
    margin-bottom: 13px;
    @include themify($themes) {
      color: themed('text-primary-color');
    }
  }

  .section-wrap-detailed {
    .item {
      margin-bottom: 6px;

      .item-title {
        font-weight: 500;
        margin-right: 5px;
      }
    }

    .item-description-content {
      padding: 15px 30px;
      margin-top: 12px;
      @include themify($themes) {
        background-color: themed('active-item-background-color');
      }

      p {
        margin-bottom: 9px;
      }

      strong {
        font-weight: 500;
        @include themify($themes) {
          color: themed('text-primary-color');
        }
      }

      a {
        @include themify($themes) {
          color: themed('link-color');
          font-weight: 500;
        }

        &:hover {
          @include themify($themes) {
            color: themed('secondary-color');
          }
        }
      }
    }

    .item-warning {
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }

  .real-score {
    font-weight: 500;
  }
}
@media (max-width: 767px) {
  .ant-modal {
    width: calc(100vw - 16px) !important;
  }

  .modal-full-height {
    max-width: 100% !important;
    margin: 0 !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .modal_dialog_ant {
    .ant-modal-content {
      h1 {
        font-size: 20px;
      }

      .warning-item {
        div {
          font-size: 100% !important;
        }
      }
    }

    .ant-modal-body {
      overflow: hidden !important;
      max-height: 100% !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .modal_dialog_ant {
    .ant-btn {
      font-size: 11px;
    }
  }
}
