@import 'layouts/theme.scss';

.submission-area {
  display: flex;
  flex-direction: column;

  .lecture-pdf {
    &__navigation {
      padding-bottom: 10px;
      margin-left: 0 !important;
      width: calc(100% + 25px) !important;
    }
  }

  &__upload-btn {
    margin: 3px auto 0;
    text-align: center;
    padding: 20px;
    @include themify($themes) {
      border: 2px dashed themed('gray60');
    }
  }

  .lecture-pdf__navigation {
    padding-bottom: 10px;
    margin-left: 0 !important;
    width: calc(100% + 25px) !important;
  }
}

.open-ended-question-form {
  .table-open-ended-question {
    textarea.ant-input {
      margin-bottom: 0;
    }
    table{
      table-layout: fixed;
    }
  }
}