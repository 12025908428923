.table-editor {
  width: 100%;

  .ant-table-header-column {
    display: block !important;
  }

  tr {
    td {
      padding: 6px;
    }
  }

  &__hidden-header {
    .ant-table-thead {
      display: none;
    }
  }

  .ve-close-outline {
    font-size: 12px;
  }

  &__table-header {
    position: relative;

    .remove-column-icon {
      position: absolute;
      top: -18px;
      right: -3px;
    }
  }
}