@import "layouts/theme.scss";

.copy-clipboard {
  position: relative;
  display: inline-block;

  &__value {
    display: block;
    font-weight: bold;
  }

  &__copy {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &__copy-text {
    padding-right: 10px;
    overflow: hidden;
  }

  &__copy-icon {
    margin-left: 5px;
    &.copied {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
  }
}
