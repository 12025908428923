@import 'layouts/theme.scss';

$tiny-size: 10px;
$small-size: 14px;
$medium-size: 20px;
$large-size: 32px;

@mixin iconSize($size) {
  i, span {
    font-size: $size !important;
  }
}

.back-button {
  font-size: $small-size;
  i {
    padding: 2px;

    @include themify($themes) {
      background-color: themed('primary-color') !important;
      color: themed('light');
    }

    border-radius: 50%;
    font-size: $small-size;
  }

  &.tiny {
    @include iconSize($tiny-size);
  }
  &.small {
    @include iconSize($small-size)
  }

  &.medium {
    @include iconSize($medium-size)
  }

  &.large {
    @include iconSize($large-size)
  }
}

