@import 'layouts/theme.scss';
.lecture-video {
  position: relative;
  &__notice {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-alert-info {
    @include themify($themes) {
      background-color: themed('light');
    }
    .ant-alert-close-icon {
      font-size: 16px;
      top: 5px;
      right: 2px;
      .anticon-close {
        @include themify($themes) {
          color: themed('danger');
        }
      }
    }
  }
}