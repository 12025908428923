.learn-element-wrapper {
  position: relative;

  .sco-standard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &--top {
      top: 8%;
      transform: inherit;
      left: 0;
    }

    &__title {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;

      &--left {
        text-align: left;
      }
    }

    &>div {
      margin: 0 15px;
    }

    &--top  {
      .sco-standard__title {
        font-size: 18px;
        margin-bottom: 25px !important;
      }
    }

    @media screen and (max-width: 768px) {
      position: relative;
      top: inherit;
      transform: inherit;
      padding: 15px 0;
      left: inherit;

      &__title {
        font-size: 16px;
      }
    }
  }
}
