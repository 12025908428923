.mc-text-question {
  font-size: 14px;
  label {
    line-height: 17px !important;
    margin-top: 4px;
    margin-left: -7px;

    font-family: 'Google Sans' !important;

    /*@include themify($themes) {
      color: themed('dark-grey') !important;
    }*/
  }

  svg:first-child {
  }

  .default-match {
    label {
      color: green !important;
    }
    svg {
      fill: green !important;
    }
  }

  .default-not-match {
    label {
      color: grey
    }
    svg {
      color: grey
    }
  }

  .display-panel {
    >div{
      display: block !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  &__radio-answer {
    table-layout: fixed;
  }
}