@import 'layouts/theme.scss';

.watch-or-read {
  position: relative;
  width: 100%;
  height: 100%;

  & .as-picture {
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 400px;
    height: 225px;
    z-index: 2000;
    padding-bottom: unset;
    overflow: hidden;

    @include themify($themes) {
      border: 1px solid themed('gray77');
    }
  }

  & .close-icon-window {
    position: absolute;
    right: -15px;
    top: -15px;
    z-index: 100;
  }

  @media screen and (max-width: 768px) {
    & .as-picture {
      display: none;
    }
  }
}
