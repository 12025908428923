.peer-assessment {
  &__panel {
    display: flex;
  }

  &__portal {
    margin-left: auto;

    @media (max-width: 768px) {
      text-align: right;
      button {
        margin-left: 5px;
      }
    }
  }
}