@import 'layouts/theme.scss';

.normal-exercise-control-question-groups {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  &__groups {
    height: 80%;
    margin: 0.2em;
    display: flex;
  }

  &__group {
    margin: 5px;
    border-radius: 30px;
    background-color: transparent;
    color: white;
    @include themify($themes) {
      border: 1px solid themed('gray77');
    }

    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2px;
    line-height: 17px;

    .normal-exercise-control-question-groups__question:first-child {
      margin-left: 0;
    }

    .normal-exercise-control-question-groups__question:last-child {
      margin-right: 0;
    }

    &--active {
      border-color: #c5e1a5;
    }

    &--single {
      border-color: transparent!important;
      padding: 0;
      margin-right: 0;
      margin-left: 0;
      float: left;

      .normal-exercise-control-question-groups__question:first-child {
        margin-left: 5px;
      }

      .normal-exercise-control-question-groups__question:last-child {
        margin-right: 5px;
      }
    }

    button {
      margin: 2px 5px !important;
    }
  }

  &__question {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 30px;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    outline: none;
    cursor: not-allowed;
    min-width: 30px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    height: 30px;
    line-height: 17px;
    padding: 0;
    @include themify($themes) {
      color: themed('l-question-cl');
      border-color: themed('l-question-border-cl');
    }

    &--clickable {
      cursor: pointer;
    }

    /*
    &--ticked {
      @include themify($themes) {
        border-color: themed('l-question-ticked-bg') !important;
      }
    }
    */

    &--correct {
      @include themify($themes) {
        color: themed('l-question-correct-cl');
        background-color: themed('l-question-correct-bg');
        border-color: themed('l-question-correct-border-cl');
      }
    }

    &--incorrect {
      @include themify($themes) {
        color: themed('l-question-incorrect-cl');
        background-color: themed('l-question-incorrect-bg');
        border-color: themed('l-question-incorrect-border-cl');
      }
    }

    &--ordered {
      @include themify($themes) {
        background-color: themed('l-question-ordered-bg');
        border-color: themed('l-question-ordered-bg');
      }
    }

    &--done {
      @include themify($themes) {
        color: themed('l-question-done-cl');
        background-color: themed('l-question-done-bg');
        border-color: themed('l-question-done-border-cl');
      }
    }

    &--touched {
      @include themify($themes) {
        background-color: themed('l-question-touched-bg');
        border-color: themed('l-question-touched-border-cl');
      }
    }

    &--current {
      @include themify($themes) {
        border-color: themed('l-question-current-border-cl');
      }
    }

    &--listening {
      @include themify($themes) {
        background-color: themed('l-question-listening-bg');
        border-color: themed('l-question-listening-bg');
      }
    }

    /*
    &--ticked {
      @include themify($themes) {
        background-color: themed('l-question-ticked-bg');
        border-color: themed('l-question-ticked-bg');
        color: themed('l-question-cl');
      }
    }
    */

    &--save-failed {
      @include themify($themes) {
        border-color: themed('l-question-save-failed-border-cl');
      }
    }
  }
}

.exam-exercise-control-question-groups {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: stretch;
  flex-flow: row wrap;

  &__groups {
    height: 80%;
    margin: 0.2em;
    display: flex;
  }

  &__group {
    padding: 0.2em;
    margin: 0.1em;
    display: inline;
    border-radius: 7px;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);

    /*
    &--active {
      border-color: #c5e1a5;
    }
    */

    &--single {
      border-color: transparent;
      margin-right: 0;
      margin-left: 0;
    }
  }
  &__question {
    margin: 0.2em;
    border-radius: 50%;
    background-color: transparent;
    outline: none;
    cursor: not-allowed;
    min-width: 32px;
    min-height: 32px;
    height: 32px;
    text-align: center;
    transition: {
      property: all;
      duration: 0.3s;
      timing-function: ease;
      delay: initial;
    }
    color: white;
    line-height: 26px;
    font: {
      family: 'Roboto', sans-serif;
      weight: bold;
    }
    @include themify($themes) {
      border: solid 2px themed('l-question-border-cl');
    }

    &--clickable {
      cursor: pointer;
      color: white;

      &:hover {
        background-color: white;
        color: #333333;
      }
    }

    &--ticked {
      @include themify($themes) {
        /*
        background-color: themed('l-question-ticked-bg');
        border-color: themed('l-question-ticked-bg');
        */
        border: 2px solid themed('secondary-color') !important;
      }
    }

    &--correct {
      @include themify($themes) {
        background-color: themed('l-question-correct-bg');
        border-color: themed('l-question-correct-bg');
      }
    }

    &--incorrect {
      @include themify($themes) {
        background-color: themed('l-question-incorrect-bg');
        border-color: themed('l-question-incorrect-bg');
      }
    }

    &--done {
      @include themify($themes) {
        background-color: themed('l-question-done-bg');
        border-color: themed('l-question-done-bg');
      }
    }
    /*
    &--touched {
      @include themify($themes) {
        background-color: themed('l-question-touched-bg');
        border-color: themed('l-question-touched-bg');
      }
    }
    */

    &--current {
      border: 2px solid yellow !important;
      border-radius: 2px;

      /*
      background: yellow;
      @include themify($themes) {
        border-color: themed('l-question-current-border-cl');
      }
      */
    }

    &--listening {
      @include themify($themes) {
        background-color: themed('l-question-listening-bg');
        border-color: themed('l-question-listening-bg');
      }
    }
    /*
    &--current.normal-exercise-control-question-groups__question--correct,
    &--current.exam-exercise-control-question-groups__question--correct {
      @include themify($themes) {
        background-color: themed('l-question-current-correct-bg');
        border-color: themed('light');
      }
    }

    &--current.normal-exercise-control-question-groups__question--incorrect,
    &--current.exam-exercise-control-question-groups__question--incorrect {
      @include themify($themes) {
        background-color: themed('l-question-current-incorrect-bg');
        border-color: themed('light');
      }
    }
    */
  }
}
