@import 'layouts/theme.scss';
.page-title {
  font-family: Google Sans;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 30px !important;
  line-height: 1.5em !important;
  @include themify($themes) {
    color: themed('text-primary-color');
  }
  &--primary {
    @include themify($themes) {
      color: themed('primary-color');
    }
  }
  &--secondary {
    @include themify($themes) {
      color: themed('secondary-color');
    }
  }
}
