@import "layouts/theme.scss";

.reorder-question-type-complex {
  &__answer-item {
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 5px;
    margin: 10px 0;
    border-radius: 5px !important;
    height: 120px;

    &.default-match:before {
      font-family: 'iconvieted';
      content: "\e905";
      @include themify($themes) {
        color: themed('success');
      }

      position: absolute;
      top: 5px;
      left: 8px;
      font-size: 12px;
    }

    &.default-not-match:before {
      font-family: 'iconvieted';
      content: "\e91d";
      @include themify($themes) {
        color: themed('danger');
      }
      position: absolute;
      top: 5px;
      left: 8px;
      font-size: 12px;
    }
  }

  &__answer-item-avatar {
    flex-shrink: 0;
    width: 150px;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__answer-item-content {
    padding: 0 10px;
    align-self: center;
  }

  &__answer-item-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 5px;
  }

  &__answer-item-numbering {
    align-self: center;
    margin: 0 10px 0 5px;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    &__answer-item-avatar {
      width: 120px;
    }
  }
}
