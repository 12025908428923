@import 'layouts/theme.scss';
.send-assessment-result {
  &__status-detail {
    &.done {
      @include themify($themes) {
        color: themed('primary-color');
      }
    }
    &.fail {
      @include themify($themes) {
        color: themed('danger');
      }
    }
    .ve-check {
      font-size: 12px;
    }
    .ve-close-outline {
      font-size: 12px;
    }
  }
  &__status-text {
    margin-left: 5px;
  }
  &__detail {
    margin-left: auto;
    padding-left: 10px;
    .ve-eye {
      font-size: 16px;
    }
  }
}