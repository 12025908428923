.admin-user-input-auto-complete-result-editor {
  height: auto;
  width: 100%;
  margin: 5px;
  padding: 10px;
  z-index: 2;
  display: flex;

  &__info {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__name-group {
    padding: 10px;
    display: flex;
  }

  &__iid {
    padding: 0 .2em;
  }

  &__delete {
    cursor: pointer;
  }
}
