@import 'layouts/theme.scss';

.wrapper-table-evidence {
  .ant-checkbox-checked .ant-checkbox-inner {
    @include themify($themes) {
      background-color: themed('primary-color');
    }
  }

  .ant-checkbox-inner {
    @include themify($themes) {
      border-color: themed('light-grey') !important;
    }

    &:after {
      @include themify($themes) {
        background-color: themed('primary-color') !important;
      }
    }
  }

  .evidence-content {
    max-width: 250px;
    padding-right: 10px;
  }
}
